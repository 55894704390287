import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../../Util/requests"
import DataPicker from "../../../Components/DataPicker"
import InputNumeric from "../../../Components/InputNumeric"
import Questionnaire from "../../../Components/Questionnaire"
import ProcessSearch from "../../../Components/ProcessSearch"
import SearchPagination from "../../../Components/SearchPagination"
import Select from "../../../Components/Select"
import image_delete from "../../../Images/Buttons/cancel.png"
import image_loading from "../../../Images/spinner.gif"

const SearchAccountTransfer = ({modeSearch, databaseType, databasePath, jsonLogin, jsonCompany, system, setAdd, setReturn, showMenu, width}) => {
  const [fieldSelected, setFieldSelected] = useState("")

  const [dateReleaseInitial, setDateReleaseInitial] = useState(null)
  const [dateReleaseFinal, setDateReleaseFinal] = useState(null)
  const [value, setValue] = useState("")
  const [situation, setSituation] = useState(0)

  const [item, setItem] = useState({})

  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({})
  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [optionsRegPage, setOptionsRegPage] = useState("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
    ", {\"ID\": 600, \"VALUE\": \"600\" }]}")

  const [upgratePage, setUpgratePage] = useState(0)    
  const [pageCurrent, setPageCurrent] = useState(1)
  const [recordPage, setRecordPage] = useState(200)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totalPage, setTotalPage] = useState(0)

  const [askQuestionnaire, setAskQuestionnaire] = useState("")
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)

  useEffect(() => {
      if (upgratePage !== 0) search()
  }, [upgratePage])

  const confirmCancel = () => {
    let returnJson = []

    let jTransferBalance = "{ \"ID\": "+ item.ID +
    ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +  ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE + 
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\" }" +
    ", \"EMPLOYEE_CANCELLATION\": { \"ID\": "+ jsonLogin.ID +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"CPF\": \""+ jsonLogin.CPF +
    "\", \"NAME\": \""+ jsonLogin.NAME +"\" }"

    jTransferBalance += ", \"ACCOUNT_ORIGIN\": { \"ID\": "+ item.ACCOUNT_ORIGIN.ID + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }" +
    ", \"NUMBER\": \""+ item.ACCOUNT_ORIGIN.NUMBER + "\", \"OWNER\": \""+ item.ACCOUNT_ORIGIN.OWNER +
    "\", \"AGENCY\": { \"ID\": "+ item.ACCOUNT_ORIGIN.AGENCY.ID + ", \"NUMBER\": \""+ item.ACCOUNT_ORIGIN.AGENCY.NUMBER +
    "\", \"DESCRIPTION\": \"" + item.ACCOUNT_ORIGIN.AGENCY.DESCRIPTION + "\", \"BANK\": { \"ID\": "+ item.ACCOUNT_ORIGIN.AGENCY.BANK.ID +
    ", \"NUMBER\": \""+ item.ACCOUNT_ORIGIN.AGENCY.BANK.NUMBER +
    "\", \"DESCRIPTION\": \"" + item.ACCOUNT_ORIGIN.AGENCY.BANK.DESCRIPTION + "\" } }"
  
    if (((item.ACCOUNT_ORIGIN.SGE !== undefined) && (item.ACCOUNT_ORIGIN.SGE !== null)) && (item.ACCOUNT_ORIGIN.SGE !== 0))
      jTransferBalance += ", \"SGE\": "+ item.ACCOUNT_ORIGIN.SGE

    jTransferBalance += " }"

    jTransferBalance += ", \"ACCOUNT_DESTINATION\": { \"ID\": "+ item.ACCOUNT_DESTINATION.ID + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }" +
    ", \"NUMBER\": \""+ item.ACCOUNT_DESTINATION.NUMBER + "\", \"OWNER\": \""+ item.ACCOUNT_DESTINATION.OWNER +
    "\", \"AGENCY\": { \"ID\": "+ item.ACCOUNT_DESTINATION.AGENCY.ID + ", \"NUMBER\": \""+ item.ACCOUNT_DESTINATION.AGENCY.NUMBER +
    "\", \"DESCRIPTION\": \"" + item.ACCOUNT_DESTINATION.AGENCY.DESCRIPTION + "\", \"BANK\": { \"ID\": "+ item.ACCOUNT_DESTINATION.AGENCY.BANK.ID +
    ", \"NUMBER\": \""+ item.ACCOUNT_DESTINATION.AGENCY.BANK.NUMBER + 
    "\", \"DESCRIPTION\": \"" + item.ACCOUNT_DESTINATION.AGENCY.BANK.DESCRIPTION + "\" } }"
  
    if (((item.ACCOUNT_DESTINATION.SGE !== undefined) && (item.ACCOUNT_DESTINATION.SGE !== null)) &&
      (item.ACCOUNT_DESTINATION.SGE !== 0)) jTransferBalance += ", \"SGE\": "+ item.ACCOUNT_DESTINATION.SGE

    jTransferBalance += " }, \"VALUE\": "+ item.VALUE +" }"

    setLoading("Aguarde cancelamento a transferência do registro...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jTransferBalance)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.put(BASE_URL + "ACCOUNT_TRANSFER/Cancel", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível cancelar a transferência da conta. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setLoading("")
        setWarning("")
        setError(returnJson.ERROR)

        setShowQuestionnaire(false)

        if (fieldSelected === "searchTransferBalanceDateReleaseFinal") {
          document.getElementById("searchTransferBalanceDateReleaseFinal") &&
            document.getElementById("searchTransferBalanceDateReleaseFinal").focus()
        }
        else if (fieldSelected === "searchTransferBalanceValue") {
          document.getElementById("searchTransferBalanceValue") &&
            document.getElementById("searchTransferBalanceValue").focus()
        }
        else if (fieldSelected === "searchTransferBalanceSituation") {
          document.getElementById("searchTransferBalanceSituation") &&
            document.getElementById("searchTransferBalanceSituation").focus()
        }
        else
          document.getElementById("searchTransferBalanceDataReleaseInitial") && document.getElementById("searchTransferBalanceDataReleaseInitial").focus() 

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setShowQuestionnaire(false)

      setLoading("")
      setWarning("")
      setError("")

      alert("Transferência cancelada com sucesso!")
      clean()
    })
  }

  const callCancel = (item) => {
    setItem(item)
    console.log(item)

    setAskQuestionnaire("Deseja realmente cancelar a transferência de conta (" + item.ID + ") ?")
    setShowQuestionnaire(true)
  }

  const cancelCancel = () => {
    setShowQuestionnaire(false)
  }  

  const clean = () => {
    setLoading("")
    setWarning("")
    setError("")

    setDateReleaseInitial(null)
    setDateReleaseFinal(null)
    setValue("")
    setSituation(0)

    setShowResult(false)
    setUpgratePage(upgratePage + 1)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const getSGE = () => {
    let returnJson = []

    setLoading("Aguarde importando os registros...")
    setWarning("")
    setError("")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_COMPANY", JSON.stringify(jsonCompany))
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "ACCOUNT_TRANSFER/GetSGE", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível obter os planos de conta no SGE. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setShowQuestionnaire(false)
        setUpgratePage(0)
        setLoading("")

        if (fieldSelected === "searchTransferBalanceDateReleaseFinal") {
          document.getElementById("searchTransferBalanceDateReleaseFinal") &&
            document.getElementById("searchTransferBalanceDateReleaseFinal").focus()
        }
        else if (fieldSelected === "searchTransferBalanceValue") {
          document.getElementById("searchTransferBalanceValue") &&
            document.getElementById("searchTransferBalanceValue").focus()
        }
        else if (fieldSelected === "searchTransferBalanceSituation") {
          document.getElementById("searchTransferBalanceSituation") &&
            document.getElementById("searchTransferBalanceSituation").focus()
        }
        else 
          document.getElementById("searchTransferBalanceDataReleaseInitial") && document.getElementById("searchTransferBalanceDataReleaseInitial").focus() 

        return
      }

      returnJson.WARNING && setWarning(returnJson.WARNING)

      setLoading("")
      setUpgratePage(upgratePage + 1)
    })
  }

  const handleValue = (event) => {
    setValue(formatNumeric(event.target.value, 2))
  }

  const handleRecordPage = (event) => {
    if (totalRecord > 0) {
      setPageCurrent(1)
      setRecordPage(event)
      setUpgratePage(upgratePage + 1)
    }
  }

  const pageNext = () => {
    if (totalRecord > 0) {
      if (pageCurrent < totalPage) {
        setPageCurrent(pageCurrent + 1)
        setUpgratePage(upgratePage + 1)
      }
    }
  }

  const pagePrevious = () => {
    if (totalRecord > 0) {
      if (pageCurrent !== 1) {
        setPageCurrent(pageCurrent - 1)
        setUpgratePage(upgratePage + 1)
      }
    }
  }

  const search = () => {
    let returnJson = []

    let search = false
    if (!search) if (value !== "") search = true
    if (!search) if (String(situation) !== "0") search = true

    if (!search)
      if ((validateDate(dateReleaseInitial)) && (validateDate(dateReleaseFinal))) search = true

    if (search){
      setLoading("Aguarde buscando as informações...")      
      setWarning("")
      setError("")

      let jAccountTransfer = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + " }"
      if (value !== "") jAccountTransfer += ", \"VALUE\": "+ value
      if (situation !== "0") jAccountTransfer += ", \"SITUATION\": \""+ situation + "\""

      if (validateDate(dateReleaseInitial) && validateDate(dateReleaseFinal)){
        const dateReleaseIni = dateReleaseInitial.toLocaleString().substr(6,4) + "-" + dateReleaseInitial.toLocaleString().substr(3,2) + "-" +
          dateReleaseInitial.toLocaleString().substr(0,2) + "T00:00:00";

        const dateReleaseFin = dateReleaseFinal.toLocaleString().substr(6,4) + "-" + dateReleaseFinal.toLocaleString().substr(3,2) + "-" +
          dateReleaseFinal.toLocaleString().substr(0,2) + "T23:59:59";

        jAccountTransfer += ", \"INITIAL_RELEASE_DATE\": \""+ dateReleaseIni + "\""
        jAccountTransfer += ", \"FINAL_RELEASE_DATE\": \""+ dateReleaseFin + "\""
      }

      jAccountTransfer += " }"

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", jAccountTransfer)
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "ACCOUNT_TRANSFER/TotalRecords", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar o total das transferências de contas. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchTransferBalanceDateReleaseFinal") {
            document.getElementById("searchTransferBalanceDateReleaseFinal") &&
              document.getElementById("searchTransferBalanceDateReleaseFinal").focus()
          }
          else if (fieldSelected === "searchTransferBalanceValue") {
            document.getElementById("searchTransferBalanceValue") &&
              document.getElementById("searchTransferBalanceValue").focus()
          }
          else if (fieldSelected === "searchTransferBalanceSituation") {
            document.getElementById("searchTransferBalanceSituation") &&
              document.getElementById("searchTransferBalanceSituation").focus()
          }
          else
            document.getElementById("searchTransferBalanceDataReleaseInitial") && document.getElementById("searchTransferBalanceDataReleaseInitial").focus() 

          return
        }

        const tRecord = returnJson.OBJECT
        if (tRecord === 0)
        {
          setWarning("Nenhum registro encontrado.")
          setShowResult(false)
          setLoading("")

          if (fieldSelected === "searchTransferBalanceDateReleaseFinal") {
            document.getElementById("searchTransferBalanceDateReleaseFinal") &&
              document.getElementById("searchTransferBalanceDateReleaseFinal").focus()
          }
          else if (fieldSelected === "searchTransferBalanceValue") {
            document.getElementById("searchTransferBalanceValue") &&
              document.getElementById("searchTransferBalanceValue").focus()
          }
          else if (fieldSelected === "searchTransferBalanceSituation") {
            document.getElementById("searchTransferBalanceSituation") &&
              document.getElementById("searchTransferBalanceSituation").focus()
          }
          else
            document.getElementById("searchTransferBalanceDataReleaseInitial") && document.getElementById("searchTransferBalanceDataReleaseInitial").focus() 

          return
        }

        const operation = tRecord / recordPage
        const numberR = Math.floor(operation)
        const decimal = operation % 1

        let tPage = 0
        if (decimal > 0) tPage = numberR + 1
        else tPage = numberR

        if (tRecord > 600)
          setOptionsRegPage("{\"RESULT\": [{\"ID\": 200, \"VALUE\": \"200\"}, {\"ID\": 400, \"VALUE\": \"400\"}"+
            ", {\"ID\": 600, \"VALUE\": \"600\" }, {\"ID\": "+ tRecord +", \"VALUE\": \"Total: "+ tRecord +"\" }]}")

        setTotalRecord(tRecord)
        setTotalPage(tPage)
        if (tPage < pageCurrent) setPageCurrent(1)

        returnJson.WARNING && setWarning(returnJson.WARNING)
      
        let qtdeInitial = 0
        if (pageCurrent > 1) qtdeInitial = ((pageCurrent - 1) * recordPage)

        formData = new FormData()
        formData.append("DATABASE_TYPE", databaseType)
        formData.append("DATABASE_PATH", databasePath)
        formData.append("JSON_NEW_1", jAccountTransfer)
        formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
        formData.append("SYSTEM", system)
        formData.append("QUANTITY_INITIAL", qtdeInitial)
        formData.append("QUANTITY_RECORDS", recordPage)

        axios.post(BASE_URL + "ACCOUNT_TRANSFER/Search", formData)
        .then((response) => { returnJson = JSON.parse(response.data) })
        .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
          ", \"ERROR\": \"Não foi possível pesquisar as transferências de contas. ("+ response +")\"}") })
        .finally(() => {
          if (returnJson.SUCCESS === false){
            setError(returnJson.ERROR)
            setShowResult(false)
            setLoading("")

            if (fieldSelected === "searchTransferBalanceDateReleaseFinal") {
              document.getElementById("searchTransferBalanceDateReleaseFinal") &&
                document.getElementById("searchTransferBalanceDateReleaseFinal").focus()
            }
            else if (fieldSelected === "searchTransferBalanceValue") {
              document.getElementById("searchTransferBalanceValue") &&
                document.getElementById("searchTransferBalanceValue").focus()
            }
            else if (fieldSelected === "searchTransferBalanceSituation") {
              document.getElementById("searchTransferBalanceSituation") &&
                document.getElementById("searchTransferBalanceSituation").focus()
            }
            else
              document.getElementById("searchTransferBalanceDataReleaseInitial") && 
                document.getElementById("searchTransferBalanceDataReleaseInitial").focus() 
            
            return
          }
            
          setResult(returnJson)
          setShowResult(true)
          setLoading("")

          if (fieldSelected === "searchTransferBalanceDateReleaseFinal") {
            document.getElementById("searchTransferBalanceDateReleaseFinal") &&
              document.getElementById("searchTransferBalanceDateReleaseFinal").focus()
          }
          else if (fieldSelected === "searchTransferBalanceValue") {
            document.getElementById("searchTransferBalanceValue") &&
              document.getElementById("searchTransferBalanceValue").focus()
          }
          else if (fieldSelected === "searchTransferBalanceSituation") {
            document.getElementById("searchTransferBalanceSituation") &&
              document.getElementById("searchTransferBalanceSituation").focus()
          }
          else
            document.getElementById("searchTransferBalanceDataReleaseInitial") && document.getElementById("searchTransferBalanceDataReleaseInitial").focus() 
        })
      })
    }
    else{
      setLoading("")
      // setWarning("")
      // setError("")
      setShowResult(false)

      if (fieldSelected === "searchTransferBalanceDateReleaseFinal") {
        document.getElementById("searchTransferBalanceDateReleaseFinal") &&
          document.getElementById("searchTransferBalanceDateReleaseFinal").focus()
      }
      else if (fieldSelected === "searchTransferBalanceValue") {
        document.getElementById("searchTransferBalanceValue") &&
          document.getElementById("searchTransferBalanceValue").focus()
      }
      else if (fieldSelected === "searchTransferBalanceSituation") {
        document.getElementById("searchTransferBalanceSituation") &&
          document.getElementById("searchTransferBalanceSituation").focus()
      }
      else
        document.getElementById("searchTransferBalanceDataReleaseInitial") && document.getElementById("searchTransferBalanceDataReleaseInitial").focus() 
    }
  }

  const validateDate = (value) => {
    let erro=false;
    let date="";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt=new Array([]);
    const ExpReg=new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt=date.split("/");        

    if ( date.search(ExpReg)===-1) erro = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) erro = true
    else if ( ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0))
        erro = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0))
        erro = true;
    }

    if (erro) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
      { loading ?
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems : "center"}}>
            <img style={{marginBottom: 10, width: 28, height: 28}} src={image_loading} alt="Carregando"/>
            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)", marginTop: 15}}>{loading}</label>
          </div>
        :
          <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            { showQuestionnaire ?
                <Questionnaire
                  ask={askQuestionnaire}
                  setConfirm={() => confirmCancel()}
                  setCancel={() => cancelCancel()} />
              :
                <>
                  <ProcessSearch modeSearch={modeSearch} jsonCompany={jsonCompany} search={search} setAdd={() => setAdd()} clean={clean} 
                    getSGE={() => getSGE()} setReturn={setReturn} />

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                  </div>

                  <div style={{paddingLeft: 10, paddingRight: 10}}>
                    { warning &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                      </div>
                    }

                    { error &&
                      <div style={{display: "flex", flexGrow: 0, justifyContent: "center", marginTop: 10, marginBottom: 10}}>
                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                      </div>
                    }

                    <div style={{paddingLeft: 10, paddingRight: 10}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginRight: 0,
                        marginBottom: 10, marginLeft: 0}}>
                        <label style={{fontWeight: 600, textAlign: "center"}}>CRITÉRIOS PARA PESQUISA</label>
                      </div>

                      <div style={
                        showMenu ?
                            (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                          :                      
                            (width < 970) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                        <div style={(width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1} :
                          {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}}>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 170}}>
                            <DataPicker
                              name="searchTransferBalanceDateReleaseInitial"
                              description="Data Inicial:"
                              value={dateReleaseInitial}
                              require={false}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDateReleaseInitial(event)} 
                              intervalo={true}
                              inicial={true}
                              endDate={dateReleaseFinal} />
                          </div>
                          <div style={(width < 420) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 170} :
                            {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, minWidth: 170}}>

                            <DataPicker
                              name="searchTransferBalanceDateReleaseFinal"
                              description="Data Final:"
                              value={dateReleaseFinal}
                              require={false}
                              onSelect={setFieldSelected}
                              onChange={(event) => setDateReleaseFinal(event)} 
                              intervalo={true}
                              inicial={false}
                              startDate={dateReleaseInitial} />
                          </div>
                        </div>
                        
                        <div style={
                            showMenu ?
                              (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                              : (width < 1050) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                            :
                              (width < 420) ? {display: "flex", flexDirection: "column", flexGrow: 1} 
                              : (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                          <div style={
                            showMenu ?
                              (width < 420) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 170} 
                              : (width < 1050) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 155 } 
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 155 }
                            :
                              (width < 420) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 170} 
                              : (width < 970) ? {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 155 } 
                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10, minWidth: 155 }}>

                            <InputNumeric
                              type="text"
                              name="searchTransferBalanceValue"
                              description="Valor:"
                              value={value || ""}
                              maxLength={13}
                              disabled={false}
                              require={false}
                              negative={false} 
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              onChange={handleValue} />
                          </div>
                          <div style={(width < 420) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10, minWidth: 170} : 
                            {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 10, minWidth: 180}}>

                            <Select
                              name="searchTransferBalanceSituation"
                              description="Situação:"
                              value={situation}
                              require={false}
                              options={JSON.parse("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}, {\"ID\": 1, \"VALUE\": \"Lançado\"}" +
                                ", {\"ID\": 2, \"VALUE\": \"Cancelado\"}]}")}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={setSituation} />
                          </div>
                        </div>
                      </div>

                      { showResult &&
                        <>
                          { result.OBJECT &&
                            <>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 15, marginBottom: 5}}>
                                <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                  <div style={
                                    showMenu ? 
                                      (width < 1360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}
                                    : 
                                      (width < 1200) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                    <div style={
                                      showMenu ? 
                                        (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : 
                                        (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={(width < 440) ? {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center",
                                          paddingLeft: 5, paddingRight: 5}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                          minWidth: 160}}>

                                        <label style={{fontWeight: 600}}>Conta Origem</label>
                                      </div>

                                      <div style={(width < 440) ? {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center",
                                          paddingLeft: 5, paddingRight: 5}
                                        : {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                          minWidth: 160}}>

                                        <label style={{fontWeight: 600}}>Conta Destino</label>
                                      </div>
                                    </div>

                                    <div style={
                                      showMenu ? 
                                        (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : (width < 1200) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={
                                        showMenu ?
                                          (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : 
                                          (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={(width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                            minWidth: 100, maxWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Data</label>
                                          </div>

                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                            minWidth: 100, maxWidth: 100}}>
                                            <label style={{fontWeight: 600}}>Dt. Canc.</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                          <div style={(width < 440) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5,
                                              paddingRight: 5, marginRight: 30}
                                            : {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                              marginRight: 30, minWidth: 160}}>

                                            <label style={{fontWeight: 600}}>Responsável</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={
                                        showMenu ? 
                                          (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                          : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : 
                                          (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                          : (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={
                                          showMenu ?
                                                (width < 1080) ? {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center",
                                                paddingRight: 5, minWidth: 125}
                                              : {display: "flex", flexGrow: 0, alignItems: "center", paddingRight: 5, minWidth: 125,
                                                maxWidth: 125}
                                            :
                                                (width < 880) ? {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center",
                                                paddingRight: 5, minWidth: 125}
                                              :
                                                {display: "flex", flexGrow: 0, alignItems: "center", paddingRight: 5, minWidth: 125,
                                                maxWidth: 125}}>

                                          <label style={{fontWeight: 600}}>Valor</label>
                                        </div>

                                        <div style={
                                          showMenu ?
                                              (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                                            :   
                                              (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingRight: 5, minWidth: 91,
                                            maxWidth: 91}}>
                                            <label style={{fontWeight: 600}}>Situação</label>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end", marginRight: 5, width: 74}} >
                                  <label>{" "}</label>
                                </div>
                              </div>
                              <hr/>

                              { result.OBJECT.map(item => (
                                <div key={item.ID} style={{display: "flex", flexDirection: "row", flexGrow: 1,
                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5,
                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 4, paddingBottom: 4, marginTop: 4,
                                  minWidth: 190}}>

                                  <div style={{display: "flex", flexGrow: 1, paddingLeft: 10, paddingRight: 10}}>
                                    <div style={
                                      showMenu ? 
                                        (width < 1360) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}
                                      : 
                                        (width < 1200) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                      <div style={
                                        showMenu ? 
                                          (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : 
                                          (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={(width < 440) ? {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center",
                                            paddingLeft: 5, paddingRight: 5}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                            minWidth: 160}}>

                                          <label>{item.ACCOUNT_ORIGIN.NUMBER} {" - "} {item.ACCOUNT_ORIGIN.OWNER}</label>
                                        </div>

                                        <div style={(width < 440) ? {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center",
                                            paddingLeft: 5, paddingRight: 5}
                                          : {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                            minWidth: 160}}>

                                          <label>{item.ACCOUNT_DESTINATION.NUMBER} {" - "} {item.ACCOUNT_DESTINATION.OWNER}</label>
                                        </div>
                                      </div>

                                      <div style={
                                        showMenu ? 
                                          (width < 1080) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1360) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : 
                                          (width < 880) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : (width < 1200) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={
                                          showMenu ?
                                            (width < 980) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          : 
                                            (width < 640) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={(width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                                  minWidth: 100, maxWidth: 100}}>

                                              <label>
                                                { String(item.RELEASE_DATE).substr(8,2) + "/" + String(item.RELEASE_DATE).substr(5,2) + "/" +
                                                  String(item.RELEASE_DATE).substr(0,4) }
                                              </label>
                                            </div>

                                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                                  minWidth: 100, maxWidth: 100}}>

                                                <label>
                                                  { item.CANCELLATION_DATE !== undefined && item.CANCELLATION_DATE !== "0001-01-01T00:00:00" ?
                                                    String(item.CANCELLATION_DATE).substr(8,2) + "/" + String(item.CANCELLATION_DATE).substr(5,2) + 
                                                      "/" + String(item.CANCELLATION_DATE).substr(0,4)

                                                      : "-"
                                                  }
                                                </label>
                                            </div>
                                          </div>

                                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                            <div style={(width < 440) ? {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5,
                                                paddingRight: 5, marginRight: 30}
                                              : {display: "flex", flexGrow: 1, alignItems: "center", paddingLeft: 5, paddingRight: 5,
                                                marginRight: 30, minWidth: 160}}>

                                              <label>{item.EMPLOYEE_RELEASE.NAME}</label>
                                            </div>
                                          </div>
                                        </div>

                                        <div style={
                                          showMenu ? 
                                            (width < 540) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                            : (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          : 
                                            (width < 440) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 5}
                                            : (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 5}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={
                                            showMenu ?
                                                  (width < 1080) ? {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center",
                                                  paddingRight: 5, minWidth: 125}
                                                : {display: "flex", flexGrow: 0, alignItems: "center", paddingRight: 5, minWidth: 125,
                                                  maxWidth: 125}
                                              :
                                                  (width < 880) ? {display: "flex", flexGrow: 1, flexBasis: 1, alignItems: "center",
                                                  paddingRight: 5, minWidth: 125}
                                                :
                                                  {display: "flex", flexGrow: 0, alignItems: "center", paddingRight: 5, minWidth: 125,
                                                  maxWidth: 125}}>

                                            <label>{formatNumeric(item.VALUE,2)}</label>
                                          </div>

                                          <div style={
                                            showMenu ?
                                                (width < 1080) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 1}
                                              :   
                                                (width < 880) ? {display: "flex", flexDirection: "row", flexGrow: 1, flexBasis: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                            <div style={{display: "flex", flexGrow: 0, alignItems: "center", paddingRight: 5, minWidth: 91,
                                              maxWidth: 91}}>
                                              <label>
                                                {item.SITUATION === 1 && "Lançado"}
                                                {item.SITUATION === 2 && "Cancelado"}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <div style={{display: "flex", justifyContent: "flex-end", marginRight: 5, width: 43}}>
                                    <div style={{display: "flex", alignItems: "center", padding: 5}}>
                                      { !modeSearch && (item.SITUATION === 1) &&
                                        <img style={{height: 32, width: 32, backgroundColor: "rgb(241, 242, 242)"}}
                                          name="searchAccountTransferCancel"
                                          src={image_delete}
                                          alt="Deletar"
                                          onClick={() => callCancel(item)} />
                                      }
                                    </div>
                                  </div>
                                </div>
                                ))
                              }

                              <SearchPagination
                                pageCurrent={pageCurrent}
                                totalPage={totalPage}
                                recordPage={recordPage}
                                optionsRegPage={JSON.parse(optionsRegPage)}
                                pagePrevious={pagePrevious}
                                pageNext={pageNext}
                                setSelectedField={setFieldSelected}
                                handleRecordPage={handleRecordPage}
                                width={width} />
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </>
            }
          </div>
      }
    </div>
  )
}


export default SearchAccountTransfer