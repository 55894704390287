import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import DataPicker from "../../Components/DataPicker"
import Header from "../../Components/Header"
import Input from "../../Components/Input"
import InputMasked from "../../Components/InputMasked"
import InputNumeric from "../../Components/InputNumeric"
import Menu from "../Menu"
import Footer from "../../Components/Footer"
import Titulo from "../../Components/Titulo"
import BillPayBordereau from "../BillPayBordereau"
import SearchAccount from "../Account/Search"
import SearchAccountPlan from "../AccountPlan/Search"
import SearchBordereauPay from "./Search"
import image_add from "../../Images/Buttons/add.png"
import image_clean from "../../Images/Buttons/clean.png"
import image_loading from "../../Images/spinner.gif";
import image_previous from "../../Images/Buttons/previous.png"
import image_save from "../../Images/Buttons/save.png"
import image_search from "../../Images/Buttons/search.png"
import image_select from "../../Images/Buttons/select_circle.png"

const BordereauPay = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [code, setCode] = useState("")
  const [openDate, setOpenDate] = useState("")
  const [paymentDate, setPaymentDate] = useState("")
  const [value, setValue] = useState("")

  const [accountCode, setAccountCode] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountOwner, setAccountOwner] = useState("")

  ////
  // Search bills // Buscar títulos
  ////
  const [emissionDateIni, setEmissionDateIni] = useState(null)
  const [emissionDateEnd, setEmissionDateEnd] = useState(null)
  const [releaseDateIni, setReleaseDateIni] = useState(null)
  const [releaseDateEnd, setReleaseDateEnd] = useState(null)
  const [dueDateIni, setDueDateIni] = useState(null)
  const [dueDateEnd, setDueDateEnd] = useState(null)

  const [documentTypeDescription, setDocumentTypeDescription] = useState("")
  const [documentTypeDescriptionSelected, setDocumentTypeDescriptionSelected] = useState("")
  const [resultDocumentType, setResultDocumentType] = useState({})
  const [resultDocumentTypeFilter, setResultDocumentTypeFilter] = useState({})

  const [accountPlanDescription, setAccountPlanDescription] = useState("")
  const [accountPlanDescriptionSelected, setAccountPlanDescriptionSelected] = useState("")
  const [resultAccountPlan, setResultAccountPlan] = useState({})
  const [resultAccountPlanFilter, setResultAccountPlanFilter] = useState({})

  const [supplierCorporateName, setSupplierCorporateName] = useState("")
  const [supplierCorporateNameSelected, setSupplierCorporateNameSelected] = useState("")
  const [resultSupplier, setResultSupplier] = useState({})
  const [resultSupplierFilter, setResultSupplierFilter] = useState({})

  const [jBillPay, setJBillPay] = useState({ CODE: 0 })    

  ////
  // Insert // Inserir
  ////
  const [billPayFilter, setBillPayFilter] = useState(true)  
  const [billPaySelected, setBillPaySelected] = useState([])

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)
  const [showChange, setShowChange] = useState(false)
  const [showCancel, setShowCancel] = useState(false)

  const [showAccount, setShowAccount] = useState(false)
  const [showAccountPlan, setShowAccountPlan] = useState(false)
  const [showSupplier, setShowSupplier] = useState(false)
  const [showDocumentType, setShowDocumentType] = useState(false)  

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {   
    if (showDocumentType){
      let arrDocumentType = []

      if (documentTypeDescription === ""){
        resultDocumentType.map(item => (
          arrDocumentType.push(item)
        ))
      }
      else{
        resultDocumentType.map(item => (
          (item.DESCRIPTION.indexOf(documentTypeDescription.toUpperCase()) !== -1) &&
            arrDocumentType.push(item)
        ))
      }
      setResultDocumentTypeFilter(arrDocumentType)
    }

    if (showAccountPlan){
      let arrAccountPlan = []

      if (accountPlanDescription === ""){
        resultAccountPlan.map(item => (
          arrAccountPlan.push(item)
        ))
      }
      else{
        resultAccountPlan.map(item => (
          (item.DESCRIPTION.indexOf(accountPlanDescription.toUpperCase()) !== -1) &&
            arrAccountPlan.push(item)
        ))
      }
      setResultAccountPlanFilter(arrAccountPlan)
    }

    if (showSupplier){
      let arrSupplier = []
  
      if (supplierCorporateName === ""){
        resultSupplier.map(item => (
          arrSupplier.push(item)
        ))
      }
      else{
        resultSupplier.map(item => (
          (item.CORPORATE_NAME.indexOf(supplierCorporateName.toUpperCase()) !== -1) &&
            arrSupplier.push(item)
        ))
      }
      setResultSupplierFilter(arrSupplier)
    }
  }, [documentTypeDescription, accountPlanDescription, supplierCorporateName])

  const add = () => {
    if (!showChange){
      setShowSearch(false)
    }

    setShowSearch(false)

    setError("")
    setWarning("")
    
    setCode("")
    setOpenDate("")
    setValue("")
    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")

    setDocumentTypeDescription("")
    setAccountPlanDescriptionSelected("")
    setAccountPlanDescription("")
    setSupplierCorporateName("")
    setSupplierCorporateNameSelected("")

    let returnJson = []

    ////
    // Get Document Type information // Carrega as informações dos Tipos de Documento
    ////
    setLoading("Aguarde buscando as informações dos Tipos de Documentos...")
    setWarning("")
    setError("")

    let formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)

    axios.post(BASE_URL + "BILL_PAY/SearchDocumentType", formData) 
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível pesquisar os Tipos de Documentos. ("+ response +")\" }") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setLoading("")
        setWarning("")
        setError(returnJson.ERROR)

        setShowSearch(true)
        return
      }

      let arrDocumentType = []
      returnJson.OBJECT &&
        returnJson.OBJECT.map(item => (
          arrDocumentType.push(item)
        ))
      setResultDocumentType(arrDocumentType)

      let arrDocumentTypeFilter = []
      returnJson.OBJECT &&
        returnJson.OBJECT.map(item => (
          arrDocumentTypeFilter.push(item)
        ))
      setResultDocumentTypeFilter(arrDocumentTypeFilter)

      ////
      // Get Account Plan information // Carrega as informações dos Planos de Conta
      ////
      setLoading("Aguarde buscando as informações dos Planos de Conta...")
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "BILL_PAY/SearchAccountPlan", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os Planos de Conta. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setLoading("")
          setWarning("")
          setError(returnJson.ERROR)

          setShowSearch(true)
          return
        }

        let arrAccountPlan = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrAccountPlan.push(item)
          ))
        setResultAccountPlan(arrAccountPlan)

        let arrAccountPlanFilter = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrAccountPlanFilter.push(item)
          ))
        setResultAccountPlanFilter(arrAccountPlanFilter)
      })

      ////
      // Get Client information // Carrega as informações dos Fornecedores
      ////
      setLoading("Aguarde buscando as informações dos Fornecedores...")
      setWarning("")
      setError("")

      formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{\"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }}")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)

      axios.post(BASE_URL + "BILL_PAY/SearchSupplier", formData) 
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar os Fornecedores. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setLoading("")
          setWarning("")
          setError(returnJson.ERROR)

          setShowSearch(true)
          return
        }

        let arrSupplier = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrSupplier.push(item)
          ))
        setResultSupplier(arrSupplier)

        let arrSupplierFilter = []
        returnJson.OBJECT &&
          returnJson.OBJECT.map(item => (
            arrSupplierFilter.push(item)
          ))
        setResultSupplierFilter(arrSupplierFilter)
        
        setShowChange(true)
        setLoading("")
      })
    })

    setShowChange(!showChange)
  }

  const addAccountPlan = () => {  
    cleanAccountPlan()
    setShowAccountPlan(true)
  }

  const addDocumentType = () => {
    cleanDocumentType()
    setShowDocumentType(true)
  }

  const addSupplier = () => {
    cleanSupplier()
    setShowSupplier(true)
  }

  const cleanAccount = () => {
    setAccountCode("")
    setAccountOwner("")
    setShowAccount(false)
  }

  const cleanAccountPlan = () => {
    resultAccountPlan.map(item => (
      item.MARKET = false
    ))

    setResultAccountPlanFilter(resultAccountPlan)

    setAccountPlanDescriptionSelected("")
    setAccountPlanDescription("")
    setShowAccountPlan(false)
  }

  const cleanBillReceive = () => {
    setEmissionDateIni(null)
    setEmissionDateEnd(null)
    setReleaseDateIni(null)
    setReleaseDateEnd(null)
    setDueDateIni(null)
    setDueDateEnd(null)

    setShowSupplier(false)
    setShowAccountPlan(false)
    setShowDocumentType(false)

    setSupplierCorporateName("")
    setSupplierCorporateNameSelected("")
    setResultSupplierFilter(resultSupplier)

    setDocumentTypeDescription("")
    setDocumentTypeDescriptionSelected("")
    setResultDocumentTypeFilter(resultDocumentType)
  
    setAccountPlanDescription("")
    setAccountPlanDescriptionSelected("")
    setResultAccountPlanFilter(resultAccountPlan)

    setJBillPay({ CODE: 0 })

    setWarning("")
    setError("")
  }

  const cleanDocumentType = () => {
    resultDocumentType.map(item => (
      item.MARKET = false
    ))

    setResultDocumentTypeFilter(resultDocumentType)

    setDocumentTypeDescriptionSelected("")
    setDocumentTypeDescription("")
    setShowDocumentType(false)
  }

  const cleanSupplier = () => {
    resultSupplier.map(item => (
      item.MARKET = false
    ))

    setResultSupplierFilter(resultSupplier)

    setSupplierCorporateNameSelected("")
    setSupplierCorporateName("")
    setShowSupplier(false)
  }

  const fillAccount = (account) => {
    setAccountCode(account.ID)
    setAccountNumber(account.NUMBER)
    setAccountOwner(account.OWNER)  
    setShowAccount(false)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."

    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const markAllAccountPlan = () => {
    let selected = ""

    resultAccountPlan.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setAccountPlanDescriptionSelected(selected)
    setShowAccountPlan(false)
  }

  const markAllDocumentType = () => {
    let selected = ""

    resultDocumentType.map(item => (
      (selected === "") ? selected = (item.ID + "-" + item.DESCRIPTION)
      : selected += ("," + item.ID + "-" + item.DESCRIPTION)
    ))
    
    setDocumentTypeDescriptionSelected(selected)
    setShowDocumentType(false)
  }

  const markAllSupplier = () => {
    let selected = ""

    resultSupplier.map(item => (
      (selected === "") ? selected = (item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
      : selected += ("," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME)
    ))
    
    setSupplierCorporateNameSelected(selected)
    setShowSupplier(false)
  }

  const returnAdd = () => {
    setWarning("")
    setError("")

    ////
    // Fields Bordereau // Campos Bordero
    ///
    setCode("")
    setOpenDate("")
    setPaymentDate("")
    setValue("")
    setAccountCode("")
    setAccountNumber("")
    setAccountOwner("")
    
    ////
    // Fields Search Bills //  Campos buscar títulos
    ////
    setDocumentTypeDescription("")
    setDocumentTypeDescriptionSelected("")
    setAccountPlanDescription("")
    setAccountPlanDescriptionSelected("")
    setSupplierCorporateName("")
    setSupplierCorporateNameSelected("")
    setEmissionDateIni(null)
    setEmissionDateEnd(null)
    setReleaseDateIni(null)
    setReleaseDateEnd(null)
    setDueDateIni(null)
    setDueDateEnd(null)

    setJBillPay({ CODE: 0 })
    
    setBillPayFilter(true)
    setBillPaySelected([])
            
    setShowAccount(false)
    setShowAccountPlan(false)
        
    setShowSearch(true)
    setShowChange(false)
    setShowCancel(false)
  }

  const returnFilter = () => {
    let search = false

    if (documentTypeDescriptionSelected !== "") search = true
    if (!search) if (accountPlanDescriptionSelected !== "") search = true
    if (!search) if (supplierCorporateNameSelected !== "") search = true
    if (!search) if (validateDate(emissionDateIni) && validateDate(emissionDateEnd)) search = true
    if (!search) if (validateDate(releaseDateIni) && validateDate(releaseDateEnd)) search = true
    if (!search) if (validateDate(dueDateIni) && validateDate(dueDateEnd)) search = true

    if (!search){
      setError("É necessário informar algum filtro para a pesquisa!")
      if (jBillPay.CODE !== 0) setJBillPay({ CODE: 0 })
    }        
    else        
    {            
      let jAccount = "{"
      jAccount += " \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"SITUATION\": 99"
      if (documentTypeDescriptionSelected !== "") jAccount += ", \"DOCUMENT_TYPES\": \""+ documentTypeDescriptionSelected +"\""
      if (accountPlanDescriptionSelected !== "") jAccount += ", \"ACCOUNTS_PLAN\": \""+ accountPlanDescriptionSelected +"\""
      if (supplierCorporateNameSelected !== "") jAccount += ", \"CLIENTS\": \""+ supplierCorporateNameSelected +"\""      

      if (validateDate(emissionDateIni) && validateDate(emissionDateEnd)){
        const emissionI = emissionDateIni.toLocaleString()
        const emissionE = emissionDateEnd.toLocaleString()

        const emissionIni = String(emissionI).substr(6,4) + "-" + String(emissionI).substr(3,2) + "-" + String(emissionI).substr(0,2) + "T00:00:00"
        const emissionEnd = String(emissionE).substr(6,4) + "-" + String(emissionE).substr(3,2) + "-" + String(emissionE).substr(0,2) + "T23:59:59"
        jAccount += ", \"EMISSION_DATE_START\": \"" + emissionIni + "\", \"EMISSION_DATE_END\": \"" + emissionEnd + "\""
      }

      if (validateDate(releaseDateIni) && validateDate(releaseDateEnd)){
        const releaseI = releaseDateIni.toLocaleString()
        const releaseE = releaseDateEnd.toLocaleString()

        const releaseIni = String(releaseI).substr(6,4) + "-" + String(releaseI).substr(3,2) + "-" + String(releaseI).substr(0,2) + "T00:00:00"
        const releaseEnd = String(releaseE).substr(6,4) + "-" + String(releaseE).substr(3,2) + "-" + String(releaseE).substr(0,2) + "T23:59:59"
        jAccount += ", \"RELEASE_DATE_START\": \"" + releaseIni + "\", \"RELEASE_DATE_END\": \"" + releaseEnd + "\""
      }

      if (validateDate(dueDateIni) && validateDate(dueDateEnd)){
        const dueI = dueDateIni.toLocaleString()
        const dueF = dueDateEnd.toLocaleString()

        const dueIni = String(dueI).substr(6,4) + "-" + String(dueI).substr(3,2) + "-" + String(dueI).substr(0,2) + "T00:00:00"
        const dueFim = String(dueF).substr(6,4) + "-" + String(dueF).substr(3,2) + "-" + String(dueF).substr(0,2) + "T23:59:59"
        jAccount += ", \"DUE_DATE_START\": \"" + dueIni + "\", \"DUE_DATE_END\": \"" + dueFim + "\""
      }

      jAccount += " }"
      setJBillPay(jAccount)
    }
  }  

  const reverseBillPay = (bills) => {
    let returnJson = []
    let jsonBordereauPay_New = {}

    setWarning("")
    setError("")

    if (bills.length === 0){
        alert("É necessário selecionar as contas a pagar.")
        return
    }

    let aSelected = []
    bills.map(item => (
      item.MARKET && aSelected.push(item)
    ))

    if (aSelected.length === 0){
      alert("É necessário selecionar as contas a pagar.")
      return
    }

    setLoading("Aguarde cancelando o registro...")

    const jsonBordereauPayCompany_New = " \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }"

    const jsonBordereauPayAccount_New = ", \"ACCOUNT\": { \"ID\": "+ accountCode +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }"+
      ", \"NUMBER\": \""+ accountNumber +"\", \"OWNER\": \""+ accountOwner +"\" }"

    const jsonBordereauPayEmployee_New = ", \"EMPLOYEE\": { \"ID\": "+ jsonLogin.ID +", \"NAME\": \""+ jsonLogin.NAME +"\" }"
    const openDat = openDate.substr(6,4) + "-" + openDate.substr(3,2) + "-" + openDate.substr(0,2) + "T00:00:00"
    
    let cancelDate = new Date();
    cancelDate = cancelDate.toLocaleDateString().substr(6,4) + "-" + cancelDate.toLocaleDateString().substr(3,2) + "-" + 
      cancelDate.toLocaleDateString().substr(0,2) + "T00:00:00"

    jsonBordereauPay_New = "{ \"ID\": "+ code +", \"OPEN_DATE\": \""+ openDat +"\", \"CANCEL_DATE\": \""+ cancelDate +"\""+
      ", \"VALUE\": "+ value +", \"SITUATION\": 2, "+ jsonBordereauPayCompany_New + jsonBordereauPayAccount_New + 
        jsonBordereauPayEmployee_New + " }"

    const formData = new FormData();
    formData.append("DATABASE_TYPE", databaseType);
    formData.append("DATABASE_PATH", databasePath);
    formData.append("JSON_NEW_1", jsonBordereauPay_New);
    formData.append("JSON_NEW_2", JSON.stringify(aSelected));
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin));
    formData.append("SYSTEM", system);

    axios.put(BASE_URL + "BORDEREAU_PAY/Cancel", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível cancelar o Bordero a Pagar. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        return
      }

      alert(returnJson.OBJECT)

      setLoading("")
      returnAdd()
    })
  }

  const save = (billPaySelected) => {
    let returnJson = []
    let jsonBordereauPay_New = {}

    setWarning("")
    setError("")

    if (!validateDate(paymentDate)){
      setError("É necessário informar a data do pagamento!")
      document.getElementById("bordereauPayPaymentDate").focus()
      return                
    }

    if (accountCode === ""){
      setError("É necessário informar a conta!")
      return
    }

    setLoading("Aguarde incluindo o registro...")

    const jsonBordereauPayCompany_New = " \"COMPANY\": { \"ID\": "+ jsonCompany.ID + "}"

    const jsonBordereauPayAccount_New = ", \"ACCOUNT\": { \"ID\": "+ accountCode +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }"+
      ", \"NUMBER\": \""+ accountNumber +"\", \"OWNER\": \""+ accountOwner +"\" }"

    const jsonBordereauPayEmployee_New = ", \"EMPLOYEE\": { \"ID\": "+ jsonLogin.ID +", \"NAME\": \""+ jsonLogin.NAME +"\" }"        

    let date  = new Date()
    date = date.toLocaleDateString().substr(6,4) + "-" + date.toLocaleDateString().substr(3,2) + "-" + date.toLocaleDateString().substr(0,2) + "T00:00:00"

    let paymentDat = paymentDate.toLocaleDateString()
    paymentDat = paymentDat.substr(6,4) + "-" + paymentDat.substr(3,2) + "-" + paymentDat.substr(0,2) + "T00:00:00"

    jsonBordereauPay_New = "{ \"OPEN_DATE\": \""+ date +"\", \"PAYMENT_DATE\": \""+ paymentDat +"\", \"SITUATION\": 1, "+ 
      jsonBordereauPayCompany_New + jsonBordereauPayAccount_New + jsonBordereauPayEmployee_New + " }"

    const formData = new FormData();
    formData.append("DATABASE_TYPE", databaseType);
    formData.append("DATABASE_PATH", databasePath);
    formData.append("JSON_NEW_1", jsonBordereauPay_New);
    formData.append("JSON_NEW_2", JSON.stringify(billPaySelected));
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin));
    formData.append("SISTEMA", system);
    formData.append("GET_ID", true);

    axios.post(BASE_URL + "BORDEREAU_PAY/Insert", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível incluir o Bordero a Pagar. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setError(returnJson.ERROR)
        setWarning("")
        setLoading("")
        return
      }

      alert(returnJson.OBJECT)

      setLoading("")
      returnAdd()
    })
  }

  const saveBillPay = (bills) => {
    if (bills.length === 0){
      alert("É necessário selecionar as contas pagar.")
      return
    }

    let aSelected = []
    bills.map(item => (
      item.MARKET && aSelected.push(item)
    ))

    if (aSelected.length === 0){
      alert("É necessário selecionar as contas pagar.")
      return
    }

    setPaymentDate(new Date())
    setBillPaySelected(aSelected)
    setBillPayFilter(false)

    console.log(aSelected)
  }  

  const selected = (event) => {
    setShowSearch(false)
    setShowChange(true)
    setShowAccount(false)

    setCode(event.ID)

    const sOpenDate = event.OPEN_DATE.substr(8, 2) + "/" + event.OPEN_DATE.substr(5, 2) + "/" + event.OPEN_DATE.substr(0, 4)
    setOpenDate(sOpenDate)

    setValue(formatNumeric(event.VALUE, 2))
    setAccountCode(event.ACCOUNT.ID);
    setAccountNumber(event.ACCOUNT.NUMBER);
    setAccountOwner(event.ACCOUNT.OWNER);
  }   

  const selectAccountPlan = () => {
    setShowAccountPlan(false)
  }

  const selectDocumentType = () => {
    setShowDocumentType(false)
  }

  const selectSupplier = () => {
    setShowSupplier(false)
  }

  const setFieldSelected = () => {
  }

  const updateShowAccountPlan = (id) => {
    resultAccountPlan.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultAccountPlan.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
                       : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
    ))
    setAccountPlanDescriptionSelected(selected)
  }

  const updateShowDocumentType = (id) => {
    resultDocumentType.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultDocumentType.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.ID + "-" + item.DESCRIPTION
                       : selected = selected + "," + item.ID + "-" + item.DESCRIPTION
      : ""
    ))
    setDocumentTypeDescriptionSelected(selected)
  }

  const updateShowSupplier = (id) => {
    resultSupplier.map(item => (
      (item.ID === id) ? item.MARKET = !item.MARKET : ""
    ))

    let selected = ""
    resultSupplier.map(item => (
      (item.MARKET) ? (selected === "") ? selected = item.CPF_CNPJ + "-" + item.CORPORATE_NAME
                       : selected = selected + "," + item.CPF_CNPJ + "-" + item.CORPORATE_NAME
      : ""
    ))
    setSupplierCorporateNameSelected(selected)
  }

  const validateDate = (value) => {
    let error = false;
    let date = "";
    if ((value !== null) && (value !== "")) date = value.toLocaleString()

    let ardt = new Array([]);
    const ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");        

    if (date.search(ExpReg)===-1) error = true
    else if (((ardt[1]===4)||(ardt[1]===6)||(ardt[1]===9)||(ardt[1]===11))&&(ardt[0]>30)) 
      error = true
    else if (ardt[1]===2) {
      if ((ardt[0]>28)&&((ardt[2]%4)!==0)) error = true;
      if ((ardt[0]>29)&&((ardt[2]%4)===0)) error = true;
    }

    if (error) return false;
    return true;
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>
          <Header
            title="Bordero Conta a Pagar"
            titleSearch="Pesquisa dos Borderos a Pagar"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
              <SearchBordereauPay
                modeSearch={modeSearch}
                databaseType={databaseType}
                databasePath={databasePath}
                jsonLogin={jsonLogin}
                jsonCompany={jsonCompany}
                system={system}
                setAdd={add}
                setSelected={selected}
                setReturn={setReturn}
                showMenu={showMenu}
                width={width} />
            :
              <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                { showChange &&
                  <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

                    { loading ?
                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                          <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                          <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                        </div>
                      :
                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>

                          { !showAccount && !showCancel && code &&
                            <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                              <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                                <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                  paddingLeft: 10}}>
                                  { warning && 
                                    <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                      marginBottom: 10}}>
                                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                    </div> 
                                  }

                                  { error &&
                                    <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                      marginBottom: 10}}>
                                      <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                    </div>
                                  }
                                </div>

                                <div style={(showMenu) ?
                                    (width < 1520) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                  :
                                    (width < 1250) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                    : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                  <div style={(showMenu) ?
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : (width < 1520) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}
                                    :
                                      (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : (width < 1250) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                    <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                      : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                      <InputMasked
                                        type="text"
                                        name="bordereauPayOpenDate"
                                        description="Data Abertura"
                                        mask="99/99/9999"
                                        value={openDate}
                                        disabled={false}
                                        require={true}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected} />
                                    </div>
                                      
                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 1520) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}
                                      :
                                        (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : (width < 1250) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 180, maxWidth: 180}}>
                                      <InputNumeric
                                        type="text"
                                        name="bordereauPayValue"
                                        description="Valor"
                                        value={value || ""}
                                        maxLength={13}
                                        disabled={true}
                                        require={false}
                                        negative={false} 
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                  </div>
                                  
                                  <div style={(showMenu) ?
                                      (width < 1160) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : (width < 1520) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                    :
                                      (width < 890) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                      : (width < 1250) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                    <div style={(showMenu) ?
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : (width < 1160) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      :
                                        (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                        : (width < 890) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                        <Input
                                          type="text"
                                          name="bordereauPayAccountCode"
                                          description="Código"
                                          value={accountCode}
                                          maxLength={11}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2} 
                                          onSelect={setFieldSelected} />
                                      </div>
                                      
                                      <div style={(showMenu) ?
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 1160) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}
                                        :
                                          (width < 450) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                          : (width < 890) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                          : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 160, maxWidth: 160}}>
                                        <Input
                                          type="text"
                                          name="bordereauPayAccountNumber"
                                          description="Número"
                                          value={accountNumber}
                                          maxLength={10}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected} />
                                      </div>
                                    </div>
                                    
                                    <div style={(showMenu) ?
                                        (width < 1160) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                      :
                                        (width < 890) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                        : {display: "flex",  flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                      <Input
                                        type="text"
                                        name="bordereauPayAccountOwner"
                                        description="Titular"
                                        value={accountOwner}
                                        maxLength={50}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2} 
                                        onSelect={setFieldSelected} />
                                    </div>
                                  </div>
                                </div>

                                <BillPayBordereau
                                  databaseType={databaseType}
                                  databasePath={databasePath}
                                  jsonCompany={jsonCompany}
                                  jsonLogin={jsonLogin}
                                  system={system}
                                  jBillPay={"{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }, \"BORDEREAU_PAY\": { \"ID\": "+ code +" } }"}
                                  saveBillPay={saveBillPay}
                                  reverseBillPay={reverseBillPay}
                                  returnBordereau={returnAdd} 
                                  newBordereau={false} 
                                  width={width}
                                  showMenu={showMenu} />
                              </div>
                            </div>
                          }

                          { !showAccount && !billPayFilter && !code &&
                            <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                              <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                  marginLeft: 5, width: 147}} onClick={() => save(billPaySelected)}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                    paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_save} alt="Salvar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Salvar</label>
                                  </div>
                                </div>

                                <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10, 
                                  marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                  <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, paddingRight: 10,                                  
                                    paddingBottom: 5, paddingLeft: 10}}>
                                    <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                  </div>
                                  <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                    <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                  </div>
                                </div>
                              </div>

                              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                              </div>

                              <div style={(width < 480) ? { paddingLeft: 10, paddingRight: 10} : { paddingLeft: 20, paddingRight: 20}}>
                                <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                  paddingLeft: 10}}>
                                  { warning && 
                                    <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                      marginBottom: 10}}>
                                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                    </div> 
                                  }

                                  { error &&
                                    <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                      marginBottom: 10}}>
                                      <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                    </div>
                                  }
                                </div>
                              </div>

                              <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
                                <div style={(width < 450) ? {paddingLeft: 0, paddingRight: 0} : {paddingLeft: 10, paddingRight: 10}}> 
                                  <div>
                                    <Titulo description="Contas a pagar selecionadas" />
                                  </div>

                                  { billPaySelected && (billPaySelected.length > 0) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, borderBottomColor: "black", borderBottomWidth: 2, 
                                      borderBottomStyle: "solid", marginTop: 30, marginBottom: 4}}>

                                      <div style={(showMenu) ? 
                                          (width < 2170) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : 
                                          (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={showMenu ?
                                            (width < 1310) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          :
                                            (width < 1030) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={showMenu ?
                                              (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}}>
                                              <div style={showMenu ?
                                                  (width < 1050) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", marginLeft: 15, minWidth: 180}
                                                  : (width < 1310) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 500}
                                                  : (width < 2170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 500}
                                                  : {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 500, maxWidth: 500}
                                                :
                                                  (width < 780) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 180}
                                                  : (width < 1910) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 500}
                                                  : {display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 500, maxWidth: 500}}>
                                                <label style={{fontWeight: 600}}>Número/Parcela</label>
                                              </div>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 1050) ? {display: "flex", flexGrow: 1, marginLeft: 20, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                              :
                                                (width < 780) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                              <label style={{fontWeight: 600}}>Vencto.</label>
                                            </div>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                              : (width < 1310) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                            :
                                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                              : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                            <div style={showMenu ?
                                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180, marginLeft: 5}
                                                : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110, marginLeft: 5}
                                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                              :
                                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                              <label style={{fontWeight: 600}}>Valor</label>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 110, marginLeft: 5}
                                                : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 110}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, marginRight: 5, minWidth: 110, maxWidth: 110}
                                              :
                                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 110}
                                                : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 110}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, marginRight: 5, minWidth: 110, maxWidth: 110}}>
                                              <label style={{fontWeight: 600}}>Pago</label>
                                            </div>
                                          </div>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 20}
                                            : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 20}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                          :
                                            (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                            : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                          <div style={showMenu ?
                                              (width < 1310) ?{display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: -5, minWidth: 430}
                                            :
                                              (width < 1030) ?{display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                              : (width < 1910) ? {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", minWidth: 430}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: -5, minWidth: 430}}>
                                            <label style={{fontWeight: 600}}>Fornecedor</label>
                                          </div>
                                          <div style={showMenu ?
                                              (width < 1310) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 5, minWidth: 430}
                                            :
                                              (width < 1030) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 5, minWidth: 430}}>
                                            <label style={{fontWeight: 600}}>Plano Conta</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  { billPaySelected && billPaySelected.map(item => (
                                    <div key={item.ID} style={{display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                      backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                      borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                      marginTop: 4, minWidth: 180}}>

                                      <div style={(showMenu) ? 
                                          (width < 2170) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}
                                        : 
                                          (width < 1910) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={showMenu ?
                                            (width < 1310) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}
                                          :
                                            (width < 1030) ?{display: "flex", flexDirection: "column", flexGrow: 1}
                                            : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={showMenu ?
                                              (width < 1050) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            :
                                              (width < 780) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 5}}>
                                              <div style={showMenu ?
                                                  (width < 1050) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 180}
                                                  : (width < 2170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 500}
                                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}
                                                :
                                                  (width < 780) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 180}
                                                  : (width < 1910) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 500}
                                                  : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 500, maxWidth: 500}}>
                                                <label>{item.NUMBER + "/" + item.PORTION}</label>
                                              </div>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 1050) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}
                                              :
                                                (width < 780) ? {display: "flex", flexGrow: 1, marginLeft: 15, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 90, maxWidth: 90}}>
                                              <label>{String(item.DUE_DATE).substr(8,2) + "/" + String(item.DUE_DATE).substr(5,2) + "/" + 
                                                String(item.DUE_DATE).substr(0,4)}</label>
                                            </div>
                                          </div>

                                          <div style={showMenu ?
                                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                              : (width < 1310) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}
                                            :
                                              (width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                              : (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0, marginLeft: 10}}>

                                            <div style={showMenu ?
                                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                              :
                                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 110}
                                                : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                              <label>{formatNumeric(item.VALUE,2)}</label>
                                            </div>

                                            <div style={showMenu ?
                                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 110}
                                                : (width < 1310) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 110}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}
                                              :
                                                (width < 450) ? {display: "flex", flexGrow: 1, minWidth: 110}
                                                : (width < 1030) ? {display: "flex", flexGrow: 1, flexBasis: 1, marginLeft: 10, minWidth: 110}
                                                : {display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 110, maxWidth: 110}}>
                                              <label>{item.VALUE_PAID !== 0 ? formatNumeric(item.VALUE_PAID,2) : "0.00"}</label>
                                            </div>
                                          </div>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 1310) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                            : (width < 2170) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                          :
                                            (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginLeft: 15}
                                            : (width < 1910) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 15}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                          <div style={showMenu ?
                                              (width < 1310) ?{display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", minWidth: 430}
                                            :
                                              (width < 1030) ?{display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", minWidth: 430}}>
                                            <label>{item.SUPPLIER && item.SUPPLIER.CORPORATE_NAME}</label>
                                          </div>
                                          <div style={showMenu ?
                                              (width < 1310) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 430}
                                            :
                                              (width < 1030) ? {display: "flex", flexGrow: 1, wordWrap: "break-word", minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, wordWrap: "break-word", marginLeft: 10, minWidth: 430}}>
                                            <label>{item.ACCOUNT_PLAN && item.ACCOUNT_PLAN.DESCRIPTION}</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    ))
                                  }

                                  <div style={showMenu ?
                                      (width < 1180) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={showMenu ?
                                        (width < 1180) ? {display: "flex", flexGrow: 0, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", minWidth: 140, maxWidth: 140}
                                      :
                                        (width < 910) ? {display: "flex", flexGrow: 0, minWidth: 180}
                                        : {display: "flex", flexGrow: 0, alignItems: "end", minWidth: 140, maxWidth: 140}}>
                                      <DataPicker
                                        name="bordereauPayPaymentDate"
                                        description="Data Pagto."
                                        value={paymentDate}
                                        require={false}
                                        onSelect={setFieldSelected}
                                        onChange={(event) => setPaymentDate(event)} 
                                        intervalo={true}
                                        inicial={true} />
                                    </div>

                                    <div style={showMenu ?
                                        (width < 1180) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}
                                      :
                                        (width < 910) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1, marginLeft: 10}}>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                        <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                          <b>Conta:</b>
                                        </div>

                                        <div style={showMenu ?
                                            (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}
                                          :
                                            (width < 760) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                            : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                          <div style={showMenu ?
                                              (width < 920) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}
                                            :
                                              (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                              : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                            <div style={showMenu ?
                                                (width < 920) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}
                                              :
                                                (width < 660) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                : {display: "flex", flexGrow: 0, minWidth: 160, maxWidth: 160}}>
                                              <Input
                                                type="text"
                                                name="bordereauPayAccountNumber"
                                                description="Número"
                                                value={accountNumber}
                                                maxLength={10}
                                                disabled={true}
                                                require={true}
                                                image={false}
                                                typeInput={2} 
                                                onSelect={setFieldSelected} />
                                            </div>
                                            <div style={showMenu ?
                                                (width < 920) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                              :
                                                (width < 660) ? {display: "flex", flexGrow: 1, marginTop: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}}>
                                              <Input
                                                type="text"
                                                name="bordereauPayAccountOwner"
                                                description="Titular"
                                                value={accountOwner}
                                                maxLength={50}
                                                disabled={true}
                                                require={true}
                                                image={false}
                                                typeInput={2} 
                                                onSelect={setFieldSelected} />
                                            </div>
                                          </div>

                                          <div style={(showMenu) ? 
                                              (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}
                                            : 
                                              (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} 
                                              onClick={() => setShowAccount(true)}>
                                              <img style={{height: 42, width: 42}} src={image_search} alt="Pesquisar"/>
                                            </div>
                                            <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={cleanAccount}>
                                              <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> 
                            </div>
                          }

                          { !showAccount && billPayFilter && !code &&
                            <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                              <div style={{marginTop: 10}}>
                                <Titulo description="Pesquisa das Contas a Pagar" />
                              </div>

                              <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                                <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => returnFilter()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_search} alt="Pesquisar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Pesquisar</label>
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => cleanBillReceive()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                    </div>
                                  </div>

                                  <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, marginTop: 10,
                                    marginLeft: 5, width: 147}} onClick={() => returnAdd()}>
                                    <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                      paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                      <img style={{height: 32, width: 32}} src={image_previous} alt="Retornar"/>
                                    </div>
                                    <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                      <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Retornar</label>
                                    </div>
                                  </div>
                                </div>

                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                  <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                                </div>

                                <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                                  <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                                    paddingLeft: 10}}>
                                    { warning && 
                                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                        marginBottom: 10}}>
                                        <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                                      </div> 
                                    }

                                    { error &&
                                      <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                                        marginBottom: 10}}>
                                        <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                                      </div>
                                    }
                                  </div>

                                  <div style={showMenu ?
                                      (width < 930) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    :
                                      (width < 660) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                    <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                      <Input
                                        type="search"
                                        name="bordereauPaySupplierCorporateNameSelected"
                                        description="Fornecedores Selecionados"
                                        value={supplierCorporateNameSelected}
                                        maxLength={6000}
                                        disabled={true}
                                        require={false}
                                        image={false}
                                        typeInput={2}
                                        onSelect={setFieldSelected} />
                                    </div>

                                    <div style={(showMenu) ? 
                                        (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}
                                      : 
                                        (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                        : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { addSupplier() })}>
                                        <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                                      </div>
                                      <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} onClick={(() => { cleanSupplier() })}>
                                        <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                      </div>
                                    </div>
                                  </div>

                                  { showSupplier &&
                                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{width: "100%"}}>
                                          <Titulo description="Fornecedor" />
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                        marginTop: 10, 
                                          marginLeft: 5, width: 210}} onClick={() => selectSupplier()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                          marginTop: 10, marginLeft: 5, width: 210}} onClick={() => markAllSupplier()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                          marginTop: 10, marginLeft: 5, width: 210}} onClick={() => cleanSupplier()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                                      </div>

                                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10}}>
                                          <Input
                                            type="search"
                                            name="bordereauPaySupplierCorporateName"
                                            description="Nome/Razão"
                                            value={supplierCorporateName}
                                            maxLength={500}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setSupplierCorporateName(event.target.value)} />
                                        </div>

                                        { resultSupplierFilter && (resultSupplierFilter.length > 0) &&
                                          <div style={(showMenu) ?
                                              (width < 1170) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 5}
                                              :
                                                {display: "flex", flexDirection: "row", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 4}
                                            :
                                              (width < 900) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 5}
                                              :
                                                {display: "flex", flexDirection: "row", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 4}}>

                                            <div style={(showMenu) ? 
                                                (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              : 
                                                (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={(showMenu) ? 
                                                  (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                                  : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                                : 
                                                  (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                                <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                                                <div style={showMenu ?
                                                    (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                    : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                  :
                                                    (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                                  <label style={{fontWeight: 600}}>Código</label>
                                                </div>
                                              </div>
                                              <div style={showMenu ?
                                                  (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                                :  
                                                  (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                                <label style={{fontWeight: 600}}>CPF/CNPJ</label>
                                              </div>
                                            </div>
                                            <div style={showMenu ?
                                                (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}
                                              :
                                                (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                              <label style={{fontWeight: 600}}>Nome/Razão Social</label>
                                            </div>
                                          </div>
                                        }

                                        { resultSupplierFilter && resultSupplierFilter.map(item => (
                                          <div key={item.CPF_CNPJ + "_" + item.CORPORATE_NAME} style={showMenu ?
                                              (width < 1170) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}
                                              : 
                                                {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}
                                            :
                                              (width < 900) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}
                                              : 
                                                {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}}>

                                            <div style={(showMenu) ? 
                                                (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              : 
                                                (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={(showMenu) ? 
                                                  (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                                  : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                                : 
                                                  (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                                <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                                  <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowSupplier(item.ID) } />
                                                </div>

                                                <div style={showMenu ?
                                                    (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                    : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                  :
                                                    (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                                  <label>{item.ID}</label>
                                                </div>
                                              </div>
                                              <div style={showMenu ?
                                                  (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                                :  
                                                  (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                                <label>
                                                  { item.CPF_CNPJ.length === 11 &&
                                                    item.CPF_CNPJ.substr(0,3) + "." + item.CPF_CNPJ.substr(4,3) + "." + item.CPF_CNPJ.substr(6,3) + "-" + 
                                                      item.CPF_CNPJ.substr(9,2)
                                                  }

                                                  { item.CPF_CNPJ.length === 14 &&
                                                    item.CPF_CNPJ.substr(0,2) + "." + item.CPF_CNPJ.substr(2,3) + "." + item.CPF_CNPJ.substr(5,3) + "/" + 
                                                      item.CPF_CNPJ.substr(8,4) + "-" + item.CPF_CNPJ.substr(12,4)
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                            <div style={showMenu ?
                                                (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}
                                              :
                                                (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                              <label>
                                                {item.CORPORATE_NAME}
                                              </label>
                                            </div>
                                          </div>
                                          ))
                                        }

                                        { resultSupplierFilter && (resultSupplierFilter.length === 0) &&
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                                            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                                          </div>
                                        }

                                      </div>
                                    </div>
                                  }

                                  <div style={(showMenu) ?
                                      (width < 1540) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}
                                    : 
                                      (width < 1260) ? {display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}
                                      : {display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>

                                    <div style={showMenu ?
                                        (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                        : (width < 1540) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}
                                      :
                                        (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column"}
                                        : (width < 1260) ? {display: "flex", flexGrow: 1, flexDirection: "row"}
                                        : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1}}>

                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                            type="search"
                                            name="bordereauPayDocumentTypeDescriptionSelected"
                                            description="Tipos de Documentos Selecionados"
                                            value={documentTypeDescriptionSelected}
                                            maxLength={6000}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2} 
                                            onSelect={setFieldSelected} />
                                      </div>

                                      <div style={(showMenu) ? 
                                          (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : 
                                          (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} 
                                          onClick={(() => { addDocumentType() })}>
                                          <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                                        </div>
                                        <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} 
                                          onClick={(() => { cleanDocumentType() })}>
                                          <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={showMenu ?
                                        (width < 930) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                                        : (width < 1540) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}
                                      :
                                        (width < 660) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginTop: 10}
                                        : (width < 1260) ? {display: "flex", flexGrow: 1, flexDirection: "row", marginTop: 10}
                                        : {display: "flex", flexGrow: 1, flexDirection: "row", flexBasis: 1, marginLeft: 10}}>

                                      <div style={{display: "flex", flexGrow: 1, minWidth: 180}}>
                                        <Input
                                          type="search"
                                          name="bordereauPayAccountPlanDescriptionSelected"
                                          description="Plano de Contas Selecionados"
                                          value={accountPlanDescriptionSelected}
                                          maxLength={6000}
                                          disabled={true}
                                          require={false}
                                          image={false}
                                          typeInput={2}
                                          onSelect={setFieldSelected} />
                                      </div>

                                      <div style={(showMenu) ? 
                                          (width < 930) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}
                                        : 
                                          (width < 660) ? {display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center", marginTop: 10}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                        <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} 
                                          onClick={(() => { addAccountPlan() })}>
                                          <img style={{height: 42, width: 42}} src={image_add} alt="Adicionar"/>
                                        </div>
                                        <div style={{display: "flex", flexGrow: 0, alignItems: "end", marginLeft: 10}} 
                                          onClick={(() => { cleanAccountPlan() })}>
                                          <img style={{height: 42, width: 42}} src={image_clean} alt="Limpar"/>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  { showDocumentType &&
                                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{width: "100%"}}>
                                          <Titulo description="Tipo do Documento" />
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                          marginTop: 10, marginLeft: 5, width: 210}} onClick={() => selectDocumentType()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                                          marginTop: 10, marginLeft: 5, width: 210}} onClick={() => markAllDocumentType()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                          marginTop: 10, marginLeft: 5, width: 210}} onClick={() => cleanDocumentType()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                                      </div>

                                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10}}>
                                          <Input
                                            type="search"
                                            name="bordereauPayDocumentTypeDescription"
                                            description="Descrição"
                                            value={documentTypeDescription}
                                            maxLength={500}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setDocumentTypeDescription(event.target.value)} />
                                        </div>

                                        { resultDocumentTypeFilter && (resultDocumentTypeFilter.length > 0) &&
                                          <div style={(showMenu) ?
                                              (width < 1030) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 5}
                                              :
                                                {display: "flex", flexDirection: "row", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 4}
                                            :
                                              (width < 760) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 5}
                                              :
                                                {display: "flex", flexDirection: "row", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 4}}>

                                            <div style={(showMenu) ? 
                                                (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              : 
                                                (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                                              <div style={showMenu ?
                                                  (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                :
                                                  (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                                <label style={{fontWeight: 600}}>Código</label>
                                              </div>
                                            </div>
                                            <div style={showMenu ?
                                                (width < 1030) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}
                                              :
                                                (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                              <label style={{fontWeight: 600}}>Descrição</label>
                                            </div>
                                          </div>
                                        }

                                        { resultDocumentTypeFilter && resultDocumentTypeFilter.map(item => (
                                          <div key={item.ID + "_" + item.DESCRIPTION} style={showMenu ?
                                              (width < 1030) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}
                                              : 
                                                {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}
                                            :
                                              (width < 760) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                  backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                  borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                  marginTop: 4, minWidth: 180}
                                              : 
                                                {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}}>

                                            <div style={(showMenu) ? 
                                                (width < 1030) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              : 
                                                (width < 760) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                                <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowDocumentType(item.ID) } />
                                              </div>

                                              <div style={showMenu ?
                                                  (width < 1030) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                :
                                                  (width < 760) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                                <label>{item.ID}</label>
                                              </div>
                                            </div>
                                            <div style={showMenu ?
                                                (width < 1030) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}
                                              :
                                                (width < 760) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                              <label>
                                                {item.DESCRIPTION}
                                              </label>
                                            </div>
                                          </div>
                                          ))
                                        }
                                          
                                        { resultDocumentTypeFilter && (resultDocumentTypeFilter.length === 0) &&
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                                            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                                          </div>
                                        }

                                      </div>
                                    </div>
                                  }

                                  { showAccountPlan &&
                                    <div style={{display: "flex", flexGrow: 0, flexDirection: "column", marginTop: 10}}>
                                      <div style={{display: "flex", flexGrow: 0}}>
                                        <div style={{width: "100%"}}>
                                          <Titulo description="Plano de Conta" />
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "row", flexGrow: 0, flexWrap: "wrap", justifyContent: "center"}}>
                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                          marginTop: 10, marginLeft: 5, width: 210}} onClick={() => selectAccountPlan()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5, 
                                          marginTop: 10, marginLeft: 5, width: 210}} onClick={() => markAllAccountPlan()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_select} alt="Selecionar Todos"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Selecionar Todos</label>
                                          </div>
                                        </div>

                                        <div style={{display: "flex", flexDirection: "row", backgroundColor: "rgb(241, 242, 242)", borderRadius: 5,
                                          marginTop: 10, marginLeft: 5, width: 210}} onClick={() => cleanAccountPlan()}>
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", paddingTop: 5, 
                                            paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>
                                            <img style={{height: 32, width: 32}} src={image_clean} alt="Limpar"/>
                                          </div>
                                          <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
                                            <label style={{fontWeight: 600, paddingLeft: 10, paddingRight: 10}}>Limpar</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <div style={{display: "flex", flexGrow: 1, backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10}} />
                                      </div>

                                      <div style={(width < 450) ? {paddingLeft: 5, paddingRight: 5} : {paddingLeft: 10, paddingRight: 10}}>
                                        <div style={{display: "flex", flexGrow: 1, marginTop: 10}}>
                                          <Input
                                            type="search"
                                            name="bordereauPayAccountPlanDescription"
                                            description="Descrição"
                                            value={accountPlanDescription}
                                            maxLength={500}
                                            disabled={false}
                                            require={false}
                                            image={false}
                                            typeInput={2}
                                            onSelect={setFieldSelected}
                                            onChange={(event) => setAccountPlanDescription(event.target.value)} />
                                        </div>

                                        { resultAccountPlanFilter && (resultAccountPlanFilter.length > 0) &&
                                          <div style={(showMenu) ?
                                            (width < 1170) ?
                                              {display: "flex", flexDirection: "column", flexGrow: 1,
                                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                marginTop: 30, marginBottom: 5}
                                            :
                                              {display: "flex", flexDirection: "row", flexGrow: 1,
                                                borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                marginTop: 30, marginBottom: 4}
                                            :
                                              (width < 900) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 5}
                                              :
                                                {display: "flex", flexDirection: "row", flexGrow: 1,
                                                  borderBottomColor: "black", borderBottomWidth: 2, borderBottomStyle: "solid", 
                                                  marginTop: 30, marginBottom: 4}}>

                                            <div style={(showMenu) ? 
                                                (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              : 
                                                (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={(showMenu) ? 
                                                  (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                                  : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                                : 
                                                  (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                                <div style={{display: "flex", flexGrow: 0, marginLeft: 15, minWidth: 50, maxWidth: 50}} />

                                                <div style={showMenu ?
                                                    (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                    : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                  :
                                                    (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                                  <label style={{fontWeight: 600}}>Código</label>
                                                </div>
                                              </div>
                                              <div style={showMenu ?
                                                  (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                                :  
                                                  (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                                <label style={{fontWeight: 600}}>Número</label>
                                              </div>
                                            </div>
                                            <div style={showMenu ?
                                                (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}
                                              :
                                                (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                              <label style={{fontWeight: 600}}>Descrição</label>
                                            </div>
                                          </div>
                                        }

                                        { resultAccountPlanFilter && resultAccountPlanFilter.map(item => (
                                          <div key={item.ID + "_" + item.NUMBER + "_" + item.DESCRIPTION} style={showMenu ?
                                              (width < 1170) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}
                                              : 
                                                {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}
                                            :
                                              (width < 900) ?
                                                {display: "flex", flexDirection: "column", flexGrow: 1,
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}
                                              : 
                                                {display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center",
                                                backgroundColor: "rgb(241, 242, 242)", borderLeftStyle: "solid", borderLeftWidth: 5, paddingRight: 8,
                                                borderLeftColor: "rgb(0, 177, 171)", borderRadius: 5, paddingTop: 8, paddingBottom: 8,
                                                marginTop: 4, minWidth: 180}}>

                                            <div style={(showMenu) ? 
                                                (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : (width < 720) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 1170) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}
                                              : 
                                                (width < 480) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                                : (width < 900) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                              <div style={(showMenu) ? 
                                                  (width < 690) ? {display: "flex", flexDirection: "row", flexGrow: 0}
                                                  : (width < 720) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}
                                                : 
                                                  (width < 480) ? {display: "flex", flexDirection: "row", flexGrow: 1}
                                                  : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                                <div style={{display: "flex", flexGrow: 0, marginLeft: 10, minWidth: 50, maxWidth: 50}}>
                                                  <input type="checkbox" checked={item.MARKET} onChange={ () => updateShowAccountPlan(item.ID) } />
                                                </div>

                                                <div style={showMenu ?
                                                    (width < 690) ? {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                    : (width < 720) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}
                                                  :
                                                    (width < 480) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                    : {display: "flex", flexGrow: 0, minWidth: 110, maxWidth: 110}}>
                                                  <label>{item.ID}</label>
                                                </div>
                                              </div>
                                              <div style={showMenu ?
                                                  (width < 480) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 690) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 1170) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}
                                                :  
                                                  (width < 720) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                  : (width < 900) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                                  : {display: "flex", flexGrow: 0, minWidth: 150, maxWidth: 150}}>
                                                <label>{item.NUMBER}</label>
                                              </div>
                                            </div>
                                            <div style={showMenu ?
                                                (width < 1170) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}
                                              :
                                                (width < 900) ? {display: "flex", flexGrow: 1, marginLeft: 10, minWidth: 180}
                                                : {display: "flex", flexGrow: 1, minWidth: 180}}>
                                              <label>{item.DESCRIPTION}</label>
                                            </div>
                                          </div>
                                          ))
                                        }

                                        { resultAccountPlanFilter && (resultAccountPlanFilter.length === 0) &&
                                          <div style={{display: "flex", flexGrow: 0, alignItems: "center", justifyContent: "center", marginTop: 10}}>
                                            <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>Nenhum registro encontrado.</label>
                                          </div>
                                        }

                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width <= 1380) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={(width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                                          <b>Data Emissão</b>
                                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                              <DataPicker
                                                name="bordereauPayEmissionDateIni"
                                                description="Inicial"
                                                value={emissionDateIni}
                                                require={false}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setEmissionDateIni(event)} 
                                                intervalo={true}
                                                inicial={true}
                                                endDate={emissionDateEnd} />
                                            </div>

                                            <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", 
                                                marginTop: 10, minWidth: 25}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                                marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                              <label style={{fontWeight: 600}}>à</label>
                                            </div>

                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                              <DataPicker
                                                name="bordereauPayEmissionDateEnd"
                                                description="Final"
                                                value={emissionDateEnd}
                                                require={false}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setEmissionDateEnd(event)} 
                                                intervalo={true}
                                                inicial={false}
                                                startDate={emissionDateIni} />
                                            </div>
                                          </div>
                                        </div>

                                        <div style={(width < 1030) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                          <b>Data Lançamento</b>
                                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                              <DataPicker
                                                name="bordereauPayReleaseDateIni"
                                                description="Inicial"
                                                value={releaseDateIni}
                                                require={false}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setReleaseDateIni(event)} 
                                                intervalo={true}
                                                inicial={true}
                                                endDate={releaseDateEnd} />
                                            </div>

                                            <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", 
                                                marginTop: 10, minWidth: 25}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                                marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                              <label style={{fontWeight: 600}}>à</label>
                                            </div>

                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                              <DataPicker
                                                name="bordereauPayReleaseDateEnd"
                                                description="Final"
                                                value={releaseDateEnd}
                                                require={false}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setReleaseDateEnd(event)} 
                                                intervalo={true}
                                                inicial={false}
                                                startDate={releaseDateIni} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                        <b>Data Vencimento</b>
                                        <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayDueDateIni"
                                              description="Inicial"
                                              value={dueDateIni}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setDueDateIni(event)} 
                                              intervalo={true}
                                              inicial={true}
                                              endDate={dueDateEnd} />
                                          </div>

                                          <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", 
                                              marginTop: 10, minWidth: 25}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                              marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                            <label style={{fontWeight: 600}}>à</label>
                                          </div>

                                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayDueDateEnd"
                                              description="Final"
                                              value={dueDateEnd}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setDueDateEnd(event)} 
                                              intervalo={true}
                                              inicial={false}
                                              startDate={dueDateIni} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  { showMenu && (width > 1380) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <b>Data Emissão</b>
                                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayEmissionDateIni"
                                              description="Inicial"
                                              value={emissionDateIni}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setEmissionDateIni(event)} 
                                              intervalo={true}
                                              inicial={true}
                                              endDate={emissionDateEnd} />
                                          </div>

                                          <div style={{display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                                            minWidth: 25, maxWidth: 25}}>
                                            <label style={{fontWeight: 600}}>à</label>
                                          </div>

                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayEmissionDateEnd"
                                              description="Final"
                                              value={emissionDateEnd}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setEmissionDateEnd(event)} 
                                              intervalo={true}
                                              inicial={false}
                                              startDate={emissionDateIni} />
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                        <b>Data Lançamento</b>
                                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayReleaseDateIni"
                                              description="Inicial"
                                              value={releaseDateIni}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setReleaseDateIni(event)} 
                                              intervalo={true}
                                              inicial={true}
                                              endDate={releaseDateEnd} />
                                          </div>

                                          <div style={{display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                                            minWidth: 25, maxWidth: 25}}>
                                            <label style={{fontWeight: 600}}>à</label>
                                          </div>

                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayReleaseDateEnd"
                                              description="Final"
                                              value={releaseDateEnd}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setReleaseDateEnd(event)} 
                                              intervalo={true}
                                              inicial={false}
                                              startDate={releaseDateIni} />
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                        <b>Data Vencimento</b>
                                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayDueDateIni"
                                              description="Inicial"
                                              value={dueDateIni}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setDueDateIni(event)} 
                                              intervalo={true}
                                              inicial={true}
                                              endDate={dueDateEnd} />
                                          </div>

                                          <div style={{display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                                            minWidth: 25, maxWidth: 25}}>
                                            <label style={{fontWeight: 600}}>à</label>
                                          </div>

                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayDueDateEnd"
                                              description="Final"
                                              value={dueDateEnd}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setDueDateEnd(event)} 
                                              intervalo={true}
                                              inicial={false}
                                              startDate={dueDateIni} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width <= 1090) &&
                                    <div style={{display: "flex", flexDirection: "column", flexGrow: 0, marginTop: 10}}>
                                      <div style={
                                        (width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                        : {display: "flex", flexDirection: "row", flexGrow: 1}}>

                                        <div style={(width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1}
                                          : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>

                                          <b>Data Emissão</b>
                                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                              <DataPicker
                                                name="bordereauPayEmissionDateIni"
                                                description="Inicial"
                                                value={emissionDateIni}
                                                require={false}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setEmissionDateIni(event)} 
                                                intervalo={true}
                                                inicial={true}
                                                endDate={emissionDateEnd} />
                                            </div>

                                            <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", 
                                                marginTop: 10, minWidth: 25}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                                marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                              <label style={{fontWeight: 600}}>à</label>
                                            </div>

                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                              <DataPicker
                                                name="bordereauPayEmissionDateEnd"
                                                description="Final"
                                                value={emissionDateEnd}
                                                require={false}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setEmissionDateEnd(event)} 
                                                intervalo={true}
                                                inicial={false}
                                                startDate={emissionDateIni} />
                                            </div>
                                          </div>
                                        </div>

                                        <div style={(width < 740) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}
                                          : {display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>

                                          <b>Data Lançamento</b>
                                          <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                              <DataPicker
                                                name="bordereauPayReleaseDateIni"
                                                description="Inicial"
                                                value={releaseDateIni}
                                                require={false}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setReleaseDateIni(event)} 
                                                intervalo={true}
                                                inicial={true}
                                                endDate={releaseDateEnd} />
                                            </div>

                                            <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", 
                                                marginTop: 10, minWidth: 25}
                                              : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                                marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                              <label style={{fontWeight: 600}}>à</label>
                                            </div>

                                            <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                              : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                              <DataPicker
                                                name="bordereauPayReleaseDateEnd"
                                                description="Final"
                                                value={releaseDateEnd}
                                                require={false}
                                                onSelect={setFieldSelected}
                                                onChange={(event) => setReleaseDateEnd(event)} 
                                                intervalo={true}
                                                inicial={false}
                                                startDate={releaseDateIni} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10}}>
                                        <b>Data Vencimento</b>
                                        <div style={(width < 450) ? {display: "flex", flexDirection: "column", flexGrow: 0}
                                          : {display: "flex", flexDirection: "row", flexGrow: 0}}>

                                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayDueDateIni"
                                              description="Inicial"
                                              value={dueDateIni}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setDueDateIni(event)} 
                                              intervalo={true}
                                              inicial={true}
                                              endDate={dueDateEnd} />
                                          </div>

                                          <div style={(width < 450) ? {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", 
                                              marginTop: 10, minWidth: 25}
                                            : {display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center", 
                                              marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                            <label style={{fontWeight: 600}}>à</label>
                                          </div>

                                          <div style={(width < 450) ? {display: "flex", flexGrow: 1, minWidth: 180}
                                            : {display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayDueDateEnd"
                                              description="Final"
                                              value={dueDateEnd}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setDueDateEnd(event)} 
                                              intervalo={true}
                                              inicial={false}
                                              startDate={dueDateIni} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  { !showMenu && (width > 1090) &&
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 0, marginTop: 10}}>
                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1}}>
                                        <b>Data Emissão</b>
                                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayEmissionDateIni"
                                              description="Inicial"
                                              value={emissionDateIni}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setEmissionDateIni(event)} 
                                              intervalo={true}
                                              inicial={true}
                                              endDate={emissionDateEnd} />
                                          </div>

                                          <div style={{display: "flex", flexDirection: "row", flexGrow: 0, justifyContent: "center", alignItems: "center",
                                            marginTop: 20, minWidth: 25, maxWidth: 25}}>
                                            <label style={{fontWeight: 600}}>à</label>
                                          </div>

                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayEmissionDateEnd"
                                              description="Final"
                                              value={emissionDateEnd}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setEmissionDateEnd(event)} 
                                              intervalo={true}
                                              inicial={false}
                                              startDate={emissionDateIni} />
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                        <b>Data Lançamento</b>
                                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayReleaseDateIni"
                                              description="Inicial"
                                              value={releaseDateIni}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setReleaseDateIni(event)} 
                                              intervalo={true}
                                              inicial={true}
                                              endDate={releaseDateEnd} />
                                          </div>

                                          <div style={{display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20,
                                            minWidth: 25, maxWidth: 25}}>
                                            <label style={{fontWeight: 600}}>à</label>
                                          </div>

                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayReleaseDateEnd"
                                              description="Final"
                                              value={releaseDateEnd}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setReleaseDateEnd(event)} 
                                              intervalo={true}
                                              inicial={false}
                                              startDate={releaseDateIni} />
                                          </div>
                                        </div>
                                      </div>

                                      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, flexBasis: 1, marginLeft: 10}}>
                                        <b>Data Vencimento</b>
                                        <div style={{display: "flex", flexDirection: "row", flexGrow: 0}}>
                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayDueDateIni"
                                              description="Inicial"
                                              value={dueDateIni}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setDueDateIni(event)} 
                                              intervalo={true}
                                              inicial={true}
                                              endDate={dueDateEnd} />
                                          </div>

                                          <div style={{display: "flex", flexGrow: 0, justifyContent: "center", alignItems: "center", marginTop: 20, 
                                            minWidth: 25, maxWidth: 25}}>
                                            <label style={{fontWeight: 600}}>à</label>
                                          </div>

                                          <div style={{display: "flex", flexGrow: 1, flexBasis: 1, minWidth: 150}}>
                                            <DataPicker
                                              name="bordereauPayDueDateEnd"
                                              description="Final"
                                              value={dueDateEnd}
                                              require={false}
                                              onSelect={setFieldSelected}
                                              onChange={(event) => setDueDateEnd(event)} 
                                              intervalo={true}
                                              inicial={false}
                                              startDate={dueDateIni} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  <BillPayBordereau
                                    databaseType={databaseType}
                                    databasePath={databasePath}
                                    jsonCompany={jsonCompany}
                                    jsonLogin={jsonLogin}
                                    system={system}
                                    jBillPay={jBillPay}
                                    saveBillPay={saveBillPay}
                                    reverseBillPay={() => alert("")}
                                    returnBordereau={returnAdd} 
                                    newBordereau={true} 
                                    height={height}
                                    width={width}
                                    showMenu={showMenu} />
                                </div>
                              </div>
                            </div>
                          }

                          { showAccount &&
                            <div>
                              <div style={{marginTop: 10, marginBottom: 10, marginLeft: 10}}>
                                <b>Selecione a conta:</b>
                              </div>

                              <SearchAccount
                                modeSearch={true}
                                databaseType={databaseType}
                                databasePath={databasePath}
                                jsonCompany={jsonCompany}
                                jsonLogin={jsonLogin}
                                system={system}
                                setAdd={() => alert("")}
                                setSelected={fillAccount} 
                                setReturn={() => setShowAccount(false)} 
                                showMenu={showMenu}
                                width={width} />
                            </div>
                          }
                          
                        </div>
                    }

                  </div>
                }

              </div>
          }

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default BordereauPay