import React, { useState, useEffect } from "react"
import axios from "axios"
import { BASE_URL } from "../../Util/requests"
import ButtonsCadastre from "../../Components/CadastreButtons"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import InputNumeric from "../../Components/InputNumeric"
import Select from "../../Components/Select"
import Menu from "../Menu"
import SearchAccountTransfer from "./Search"
import image_loading from "../../Images/spinner.gif";

const AccountTransfer = ({modeSearch, databaseType, databasePath, jsonCompany, jsonLogin, system, setReturn, height, width, logoff}) => {
  const [enableDestination, setEnableDestination] = useState(true)

  const [codeAccountOrigin, setCodeAccountOrigin] = useState("0")
  const [numberAccountOrigin, setNumberAccountOrigin] = useState("")
  const [ownerAccountOrigin, setOwnerAccountOrigin] = useState("")

  const [codeAccountDestination, setCodeAccountDestination] = useState("0")
  const [numberAccountDestination, setNumberAccountDestination] = useState("")  
  const [ownerAccountDestination, setOwnerAccountDestination] = useState("")  

  const [value, setValue] = useState(0)

  const [accounts, setAccounts] = useState([])
  const [optionsAccount, setOptionsAccount] = useState("{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}]}")

  const [showMenu, setShowMenu] = useState(true)
  const [showSearch, setShowSearch] = useState(true)

  const [loading, setLoading] = useState(null)
  const [warning, setWarning] = useState("")
  const [error, setError] = useState("")

  const [firstAcess, setFirstAcess] = useState(true)
  const [upgrateFocu, setUpgrateFocu] = useState(0)

  useEffect(() => {
    if (firstAcess) {
      let returnJson = []

      ////
      // Loading the accounts //  Carrega as contas
      ////
      setLoading("Aguarde buscando as informações das contas...")
      setWarning("")
      setError("")      

      let formData = new FormData()
      formData.append("DATABASE_TYPE", databaseType)
      formData.append("DATABASE_PATH", databasePath)
      formData.append("JSON_NEW_1", "{ \"COMPANY\":{\"ID\":"+ jsonCompany.ID +"} }")
      formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
      formData.append("SYSTEM", system)
      formData.append("QUANTITY_INITIAL", 0)
      formData.append("QUANTITY_RECORDS", 100)

      axios.post(BASE_URL + "ACCOUNT/Search", formData)
      .then((response) => { returnJson = JSON.parse(response.data) })
      .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
        ", \"ERROR\": \"Não foi possível pesquisar as contas. ("+ response +")\" }") })
      .finally(() => {
        if (returnJson.SUCCESS === false){
          setError(returnJson.ERROR)
          setWarning("")
          setLoading("")
          return
        }

        returnJson.WARNING && setWarning(returnJson.WARNING)

        let sAccount = "{\"RESULT\": [{\"ID\": 0, \"VALUE\": \"Selecione...\"}"
        returnJson && returnJson.OBJECT && returnJson.OBJECT.map(item => (sAccount += ",{\"ID\": "+ item.ID +
          ", \"VALUE\": \""+ item.NUMBER + "-" + item.OWNER +"\"}"))
        sAccount += "]}"

        setOptionsAccount(sAccount)
        setAccounts(returnJson.OBJECT)

        setLoading("")
      })
      
      setFirstAcess(false)
    }

    document.getElementById("accountTransferCodeAccountOrigin") && document.getElementById("accountTransferCodeAccountOrigin").focus()

  }, [databaseType, databasePath, jsonLogin, jsonCompany, system, firstAcess, upgrateFocu])

  const add = () => {
    setLoading("")
    setWarning("")
    setError("")

    setCodeAccountOrigin("0")
    setNumberAccountOrigin("")
    setOwnerAccountOrigin("")

    setCodeAccountDestination("0")
    setNumberAccountDestination("")
    setOwnerAccountDestination("")

    setValue(0)

    setUpgrateFocu(upgrateFocu + 1)
  }

  const formatNumeric = (value, decimals) => {
    if (!value) return "0.00"
    if (value === ".") return value = "0."
      
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`)

    let decimalsNumber = 0
    try { decimalsNumber = value.toString().match(regex)[0] } catch { decimalsNumber = 0 }

    const parsed = parseFloat(decimalsNumber).toFixed(decimals)

    if (isNaN(parsed)) return "0.00"
    return parsed
  }

  const handleValue = (event) => {
    setValue(formatNumeric(event.target.value, 2))
  }

  const previousAdd = () => {
    setWarning("")
    setError("")

    setCodeAccountOrigin("0")
    setCodeAccountDestination("0")
    setValue(0)

    setShowSearch(true)
  }

  const save = () => {
    let returnJson = []

    setWarning("")
    setError("")

    if (codeAccountOrigin === "0") {
      setError("É necessário informar a conta de origem!")
      document.getElementById("accountTransferCodeAccountOrigin") && document.getElementById("accountTransferCodeAccountOrigin").focus()
      return
    }

    if (codeAccountDestination === "0") {
      setError("É necessário informar a conta de destino!")
      document.getElementById("accountTransferCodeAccountDestination") && document.getElementById("accountTransferCodeAccountDestination").focus()
      return
    }

    if (codeAccountOrigin === codeAccountDestination) {
      setError("Conta de origem igual a conta de destino!")
      document.getElementById("accountTransferCodeAccountOrigin") && document.getElementById("accountTransferCodeAccountOrigin").focus()      
      return
    }   

    let jsonAccountTransfer_New = "{ \"COMPANY\": { \"ID\": "+ jsonCompany.ID + ", \"INTEGRATE_SGE\": "+ jsonCompany.INTEGRATE_SGE +
    ", \"DATABASE_SGE_PATH\": \""+ jsonCompany.DATABASE_SGE_PATH +"\", \"BANK_TOKEN\": \""+ jsonCompany.BANK_TOKEN +"\"" +
    ", \"BANK_LOGIN\": \""+ jsonCompany.BANK_LOGIN +"\", \"BANK_PASSWORD\": \""+ jsonCompany.BANK_PASSWORD +
    "\", \"ENVIRONMENT\": \""+ jsonCompany.ENVIRONMENT +"\" }, \"EMPLOYEE_RELEASE\": { \"ID\": "+ jsonLogin.ID + 
    ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID + "}, \"CPF\": \""+ jsonLogin.CPF + "\", \"NAME\": \""+ jsonLogin.NAME +"\" }"+
    ", \"VALUE\": "+ value + ", \"ACCOUNT_ORIGIN\": { \"ID\": "+ codeAccountOrigin + ", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }"+
    ", \"NUMBER\": \""+ numberAccountOrigin +"\", \"OWNER\": \""+ ownerAccountOrigin +"\" }"+
    ", \"ACCOUNT_DESTINATION\": { \"ID\": "+ codeAccountDestination +", \"COMPANY\": { \"ID\": "+ jsonCompany.ID +" }"+
    ", \"NUMBER\": \""+ numberAccountDestination +"\", \"OWNER\": \""+ ownerAccountDestination +"\" }, \"SITUATION\": 1 }"

    setLoading("Aguarde incluindo o registro...")

    const formData = new FormData()
    formData.append("DATABASE_TYPE", databaseType)
    formData.append("DATABASE_PATH", databasePath)
    formData.append("JSON_NEW_1", jsonAccountTransfer_New)
    formData.append("JSON_LOGIN", JSON.stringify(jsonLogin))
    formData.append("SYSTEM", system)
    formData.append("GET_ID", true)

    axios.post(BASE_URL + "ACCOUNT_TRANSFER/Insert", formData)
    .then((response) => { returnJson = JSON.parse(response.data) })
    .catch((response) => { returnJson = JSON.parse("{ \"SUCCESS\": false, \"OBJECT\": null, \"AUDIT_CONFIGURATION\": null, \"WARNING\": null"+
      ", \"ERROR\": \"Não foi possível incluir o plano de conta. ("+ response +")\"}") })
    .finally(() => {
      if (returnJson.SUCCESS === false){
        setLoading("")
        setWarning("")
        setError(returnJson.ERROR)
        document.getElementById("accountTransferCodeAccountOrigin") && document.getElementById("accountTransferCodeAccountOrigin").focus()
        return
      }

      returnJson.WARNING ? setWarning("Inclusão efetuada com sucesso! ("+ returnJson.WARNING +")")
      : setWarning("Inclusão efetuada com sucesso!")

      setLoading("")
      setWarning("")
      setError("")
      alert("Transferência efetuada com sucesso!")

      previousAdd()
    })
  }

  const selectOrigin = (item) => {
    if (parseInt(item) !== 0){
      for (let i = 0; i < accounts.length; i++){
        if (accounts[i].ID === parseInt(item)){
          setCodeAccountOrigin(accounts[i].ID)
          setNumberAccountOrigin(accounts[i].NUMBER)
          setOwnerAccountOrigin(accounts[i].OWNER)

          if (accounts[i].REDEMPTION_ACCOUNT != null){
            setCodeAccountDestination(accounts[i].REDEMPTION_ACCOUNT.ID)
            setNumberAccountDestination(accounts[i].REDEMPTION_ACCOUNT.NUMBER)
            setOwnerAccountDestination(accounts[i].REDEMPTION_ACCOUNT.OWNER)
            setEnableDestination(false)
          }
          else{
            setCodeAccountDestination("0")
            setNumberAccountDestination("")
            setOwnerAccountDestination("")
            setEnableDestination(true)
          }          
        }
      }
    }
    else
    {
      setCodeAccountOrigin("0")
      setNumberAccountOrigin("")
      setOwnerAccountOrigin("")
      setCodeAccountDestination("0")
      setNumberAccountDestination("")
      setOwnerAccountDestination("")      
      setEnableDestination(true)
    }
  }

  const selectDestination = (item) => {
    if (item !== 0){
      for (let i = 0; i < accounts.length; i++){
        if (accounts[i].ID === parseInt(item)){
          setCodeAccountDestination(accounts[i].ID)
          setNumberAccountDestination(accounts[i].NUMBER)
          setOwnerAccountDestination(accounts[i].OWNER)          
        }
      }
    }
  }

  const setAdd = (event) => {
    setShowSearch(false)
    add()

    setUpgrateFocu(upgrateFocu + 1)
  }
   
  const setFieldSelected = () => {
  }

  return(
    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: height}}>
      <div style={(width < 690) ? {display: "flex", flexGrow: 1, flexDirection: "column", marginBottom: 10}
        : {display: "flex", flexGrow: 1, flexDirection: "row", marginBottom: 10}}>

        { showMenu &&
          <div style={{display: "flex", flexGrow: 0}}>
            <Menu jsonCompany={jsonCompany} jsonLogin={jsonLogin} showMenu={showMenu} setShowMenu={setShowMenu} height={height} width={width}
              logoff={logoff} />
          </div>
        }

        <div style={{display: "flex", flexGrow: 1, flexDirection: "column"}}>        
          <Header
            title="Transferência da Conta"
            titleSearch="Pesquisa das Transferências das Contas"
            showSearch={showSearch}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            width={width} />

          { showSearch ?
            <SearchAccountTransfer
              modeSearch={modeSearch}
              databaseType={databaseType}
              databasePath={databasePath}
              jsonLogin={jsonLogin}
              jsonCompany={jsonCompany}
              system={system}
              setAdd={setAdd}
              setReturn={setReturn} 
              showMenu={showMenu}
              width={width} />
            :
              <>
                { loading ?
                    <div style={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center"}}>
                      <img style={{marginBottom: 10, height: 32, width: 32}} src={image_loading} alt="Carregando"/>
                      <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{loading}</label>
                    </div>
                  :
                    <>
                      <ButtonsCadastre save={() => save()} add={() => add()} returnAdd={() => previousAdd()} />

                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{backgroundColor: "rgb(0, 177, 171)", marginTop: 8, height: 10, width: "98%"}} />
                      </div>
                    
                      <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <div style={{display: "flex", flexGrow: 0, flexDirection: "row", paddingTop: 0, paddingRight: 10, paddingBottom: 0,
                          paddingLeft: 10}}>
                          { warning && 
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "rgb(0, 177, 171)"}}>{warning}</label>
                            </div>
                          }

                          { error &&
                            <div style={{display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "center", marginTop: 10,
                              marginBottom: 10}}>
                              <label style={{fontWeight: 600, color: "red"}}>{error}</label>
                            </div>
                          }
                        </div>

                        <div style={
                          showMenu ? 
                            (width < 1300) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20,
                              marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}
                          : 
                            (width < 1010) ? {display: "flex", flexDirection: "column", flexGrow: 1, paddingLeft: 20, paddingRight: 20,
                              marginTop: 10}
                            : {display: "flex", flexDirection: "row", flexGrow: 1, paddingLeft: 20, paddingRight: 20, marginTop: 10}}>

                          <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 170}}>
                            <Select
                              name="accountTransferCodeAccountOrigin"
                              description="Conta Origem:"
                              value={codeAccountOrigin || "0"}
                              require={false}
                              options={JSON.parse(optionsAccount)}
                              image={false}
                              typeInput={2}
                              onSelect={setFieldSelected}
                              set={selectOrigin} />
                          </div>

                          <div style={
                            showMenu ? (width < 910) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10} : (width < 1300) ?
                              {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10} : {display: "flex", flexDirection: "row",
                                flexGrow: 1, marginLeft: 10}
                            : (width < 630) ? {display: "flex", flexDirection: "column", flexGrow: 1, marginTop: 10} : (width < 1010) ?
                              {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10} : {display: "flex", flexDirection: "row",
                                flexGrow: 1, marginLeft: 10}}>

                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 170}}>
                              <Select
                                name="accontTransferCodeAccountDestination"
                                description="Conta Destino:"
                                value={codeAccountDestination || "0"}
                                disabled={enableDestination ? false : true}
                                require={false}
                                options={JSON.parse(optionsAccount)}
                                image={false}
                                typeInput={2}
                                onSelect={setFieldSelected}
                                set={selectDestination} />
                            </div>

                            <div style={
                              showMenu ? (width < 910) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 170, maxWidth: 170, marginLeft: 10}
                              : (width < 630) ? {display: "flex", flexDirection: "row", flexGrow: 1, marginTop: 10}
                                : {display: "flex", flexDirection: "row", flexGrow: 0, minWidth: 170, maxWidth: 170, marginLeft: 10}}>

                              <div style={{display: "flex", flexDirection: "row", flexGrow: 1, minWidth: 170}}>
                                <InputNumeric
                                  type="text"
                                  name="accontTransferValue"
                                  description="Valor"
                                  value={value}
                                  maxLength={13}
                                  disabled={false}
                                  require={true}
                                  negative={false} 
                                  image={false}
                                  typeInput={2}
                                  onSelect={setFieldSelected}
                                  onChange={handleValue} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                }
              </>
          }
        </div>
      </div>
      
      <Footer/>
    </div>
  )
}

export default AccountTransfer